// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import JwtDecode from "jwt-decode";
// import authService from "./services/auth.service";

// export const ProtectedLoginRoute = ({ component: Component, ...rest }) => {
//   let isAutenticated = false;
//   authService.isAuthenticated().then(() => {
//     isAutenticated = true;
//   });
//   console.log(isAutenticated);
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!isAutenticated) {
//           return <Component {...props} />;
//         } else {
//           return (
//             <Redirect
//               to={{
//                 pathname: "/admin/clients",
//                 state: {
//                   from: props.location,
//                 },
//               }}
//             />
//           );
//         }
//       }}
//     />
//   );
// };

import React, { Component } from "react";
import userService from "./services/user.service";
import { Route, Redirect } from "react-router-dom";
import adminService from "./services/admin.service";

class ProtectedLoginRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };

    this.isAuthenticated();
  }

  isAuthenticated = async () => {
    try {
      await adminService.getUserWithoutInterceptor();
      this.setState({ isLoading: false, isLoggedIn: true }, () => console.log(this.state.isLoggedIn));
    } catch (error) {
      this.setState({ isLoading: false, isLoggedIn: false }, () => console.log(this.state.isLoggedIn));
    }
  };

  render() {
    return this.state.isLoading ? (
      <div></div>
    ) : this.state.isLoggedIn === false ? (
      <Route
        path={this.props.path}
        component={this.props.component}
        exact={this.props.exact}
      />
    ) : (
      <Redirect
        to={{
          pathname: "/admin/clients",
          state: {
            from: this.props.location,
          },
        }}
      />
    );
  }
}

export default ProtectedLoginRoute;
