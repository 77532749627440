import React, { Component } from "react";

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import categoryService from "../services/category.service";
import Header from "../helpers/Header";
import brandService from "../services/brand.service";

registerLocale("fr", fr);

class AddBrandPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      fields: {
        ...this.initialFields,
      },

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.showError = this.showError.bind(this);
  }

  initialFields = {
    name: "",
  };

  getBrandById = async (id) => {
    try {
      let response = await brandService.getBrandById(id);
      let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
      fieldsCopy["name"] = response.data.name;
      this.setState({
        fields: fieldsCopy,
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    this.setState({ fields: fieldsCopy });
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["name"] === "") {
      formIsValid = false;
      errors["name"] = "ce champs ne doit pas être vide";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  persistBrand = async () => {
    try {
      let response = await brandService.persistBrand(this.state.fields);
      this.props.history.push(`/admin/brands`, {
        successMessage: " La marque a été ajoutée avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  updateBrand = async () => {
    try {
      let response = await brandService.updateBrand(
        this.state.idToUpdate,
        this.state.fields
      );
      this.props.history.push(`/admin/brands`, {
        successMessage: " La marque a été modifiée avec succès",
      });
    } catch (error) {
      console.warn(error.response ? error.response.data.detail : error);
    }
  };

  addOrPersistBrand = async () => {
    this.setState({ errors: {} }, async () => {
      let valid1 = await this.handleValidation();
      if (valid1) {
        this.state.idToUpdate === 0 ? this.persistBrand() : this.updateBrand();
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Marques - ParaSquare";
    const id = this.props.match.params.id;
    if (id) {
      this.getBrandById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    console.log(message);
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 3000);
  }

  render() {
    const { errorMessage, errors, fields, idToUpdate } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          {errorMessage && (
            <FlashMessages successMessage={""} errorMessage={errorMessage} />
          )}
          <PopUp></PopUp>
          <TopOptions
            title={
              idToUpdate === 0
                ? "Ajouter une nouvelle marque"
                : "Modifier une marque"
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.addOrPersistBrand()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire {idToUpdate === 0 ? "d'ajout" : "de modification"}
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>détails de la marque</h3>
                </div>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={errors["name"]}
                    errorMessage={errors["name"]}
                    onChange={this.handleChange("name")}
                    value={fields["name"]}
                    id="name"
                    label="name *"
                  ></CustomInput>
                </LeftInputContainer>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;
const LeftInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;

const RightInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 51px;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(AddBrandPage);
