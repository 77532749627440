import React, { Component } from "react";
import styled from "styled-components";

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
        focus: false
    };
  }
  render() {
    const { error } = this.props;
    return (
      <Container>
        <InputContainer>
          <Input
            onClick={this.props.onClick}
            className="example-custom-input"
            borderColor={error ? "#FF068B" : "#bbc8d3"}
            type={this.props.type}
            name="input"
            autoFocus={this.props.autoFocus}
            // className={"input-login"}
            onChange={this.props.onChange}
            id={this.props.id}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            focus= {this.state.focus}
            required
          />
          <Label
            color={error ? "#FF068B" : "#284358"}
            id="input-label"
            htmlFor={this.props.id}
            className="label-login"
          >
            {this.props.label}
          </Label>
          <Svg
            width="22.035"
            height="16.86"
            aria-hidden="true"
            focusable="true"
            data-prefix="fas"
            data-icon="calendar-alt"
            className="svg-inline--fa fa-calendar-alt fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            htmlFor={this.props.id}
          >
            <path
              fill="#284358"
              d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
            ></path>
          </Svg>
        </InputContainer>
        {error && (
          <ErrorContainer>
            <svg
              aria-hidden="true"
              width="16.035"
              height="10.86"
              focusable="false"
              data-prefix="fas"
              data-icon="times-circle"
              className="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#FF068B"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
              ></path>
            </svg>
            <ErrorText>
              {this.props.errorMessage
                ? this.props.errorMessage
                : "Ce champs est obligatoire"}
            </ErrorText>
          </ErrorContainer>
        )}
      </Container>
    );
  }
}
const Container = styled.div`
  width: 100%;
  height: 41px;
`;
const Svg = styled.svg`
  position: absolute;
  top: 11px;
  right: 5px;
`;
const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
// const Label = styled.label`
//     position:absolute;
//     left: 2%;
//     top: 34%;
//     font-family: Open Sans !important;
//     font-size: 14px;
//     line-height: 0.94;
//     letter-spacing: 0.22px;
//     text-align: left;
//     color: ${(props) => props.color};
// `;
const Label = styled.label`
  position: absolute;
  left: 15px;
  top: 34%;
  ${"" /* font-family: Roboto;
    font-size: 14px; */}
  font-family: 'Open Sans', sans-serif;
  color: #494949;
  font-weight: 600;
  font-size: 12px;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
  color: ${(props) => props.color};
  font: "Open Sans", sans-serif;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: #ffffff;
  border: solid 1px ${(props) => props.borderColor};
  border-radius: 6px;
  &:hover {
    border: solid 1px #00beb0;
  }
  &:focus-within,
  &:valid {
    border: solid 1px #00beb0;
  }
  &:focus ~ ${Label},
  &:valid ~ ${Label} {
    background-color: #fff;
    top: -10%;
    width: auto;
    left: 1%;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
    color: #00beb0;
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &:not(focus) ~ ${Label},
  &:not(valid) ~ ${Label} {
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
`;
const ErrorContainer = styled.div``;
const ErrorText = styled.div`
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
`;
export default CustomDatePicker;
