import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/administration/dashboard-infos",
  headers: authHeader(),
});

class DashboardService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (401 === error.response.status) {
          if (error.response.data.message === "JWT Token not found") {
            authService.refresh();
            // window.location.reload();
            // window.location.href = '/admin/login';
          }
          // try {
          //     authService.refresh();
          //     window.location.reload();
          //   } catch (error) {
          //     console.log(error)
          //     // localStorage.clear();
          //     // window.location.href = '/login';
          //   }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getdashboardInfos() {
    return authAxios.post("", null, { withCredentials: true });
  }
}

export default new DashboardService();
