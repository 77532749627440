import React, { Component } from "react";

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import categoryService from "../services/category.service";
import Header from "../helpers/Header";
import brandService from "../services/brand.service";
import patientsService from "../services/patients.service";

registerLocale("fr", fr);

class UpdatePatientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      fields: {
        ...this.initialFields,
      },

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.showError = this.showError.bind(this);
  }

  initialFields = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  };

  getPatientById = async (id) => {
    try {
      let response = await patientsService.getPatientById(id);
      let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
      fieldsCopy["firstName"] = response.data.firstName;
      fieldsCopy["lastName"] = response.data.lastName;
      fieldsCopy["email"] = response.data.email;
      fieldsCopy["mobileNumber"] = response.data.mobileNumber ;
      this.setState({
        fields: fieldsCopy,
        is_loaded: true,
        idToUpdate: id,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    this.setState({ fields: fieldsCopy });
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["firstName"] === "") {
      formIsValid = false;
      errors["firstName"] = "Veuillez renseigner ce champ";
    }

    if (fields["lastName"] === "") {
      formIsValid = false;
      errors["lastName"] = "Veuillez renseigner ce champ";
    }

    if (fields["email"] === "") {
      formIsValid = false;
      errors["email"] = "Veuillez renseigner ce champ";
    }

    if (fields["mobileNumber"] === "") {
      formIsValid = false;
      errors["mobileNumber"] = "Veuillez renseigner ce champ";
    }

    if (fields["email"] !== "") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email n'est pas valide";
      }
    }

    if (fields["mobileNumber"] !== "") {
      if (
        !fields["mobileNumber"].match(
          /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/
        )
      ) {
        formIsValid = false;
        errors["mobileNumber"] = "Numéro invalide";
      }
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  updatePatient = async () => {
    try {
      await patientsService.updatePatient(this.state.idToUpdate, this.state.fields);
      this.props.history.push(`/admin/patients/view/${this.state.idToUpdate}`, {
        successMessage: " Le patient a été modifié avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  validateAndUpdate = async () => {
    this.setState({ errors: {} }, async () => {
      let valid1 = await this.handleValidation();
      if (valid1) {
        this.updatePatient();
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Patients - MonPharmacien";
    const id = this.props.match.params.id;
    if (id) {
      this.getPatientById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    console.log(message);
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 3000);
  }

  render() {
    const { errorMessage, errors, fields, idToUpdate } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          {errorMessage && (
            <FlashMessages successMessage={""} errorMessage={errorMessage} />
          )}
          <PopUp></PopUp>
          <TopOptions
            title="Modifier un Patient"
          >
            <button
              className="btn_bleu"
              data-shortcut_alias="create"
              onClick={() => this.validateAndUpdate()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire de modification
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>détails du patient</h3>
                </div>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={errors["firstName"]}
                    errorMessage={errors["firstName"]}
                    onChange={this.handleChange("firstName")}
                    value={fields["firstName"]}
                    id="firstName"
                    label="Prénom"
                  ></CustomInput>
                </LeftInputContainer>
                <RightInputContainer>
                <CustomInput
                    error={errors["lastName"]}
                    errorMessage={errors["lastName"]}
                    onChange={this.handleChange("lastName")}
                    value={fields["lastName"]}
                    id="lastName"
                    label="Nom"
                  ></CustomInput>
                </RightInputContainer>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={errors["email"]}
                    errorMessage={errors["email"]}
                    onChange={this.handleChange("email")}
                    value={fields["email"]}
                    id="email"
                    label="Email"
                  ></CustomInput>
                </LeftInputContainer>
                <RightInputContainer>
                <CustomInput
                    error={errors["mobileNumber"]}
                    errorMessage={errors["mobileNumber"]}
                    onChange={this.handleChange("mobileNumber")}
                    value={fields["mobileNumber"]}
                    id="mobileNumber"
                    label="Téléphone"
                  ></CustomInput>
                </RightInputContainer>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;
const LeftInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;

const RightInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 51px;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(UpdatePatientPage);
