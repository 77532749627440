import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/administration/admins",
  headers: authHeader(),
});

class AdminService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (401 === error.response.status) {
          if (error.response.data.message === "JWT Token not found") {
            authService.refresh();
            // window.location.reload();
            // window.location.href = '/admin/login';
          }
          // try {
          //     authService.refresh();
          //     window.location.reload();
          //   } catch (error) {
          //     console.log(error)
          //     // localStorage.clear();
          //     // window.location.href = '/login';
          //   }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getUser() {
    return authAxios.get(`/profile`, { withCredentials: true });
  }

  getUserWithoutInterceptor() {
    return axios.get(api_url + "/administration/admins/profile", {
      withCredentials: true,
    });
  }

  updateProfile(firstName = "", lastName = "", email = "", mobileNumber = "") {
    let data = {};
    if (firstName.length > 0) {
      data["firstName"] = firstName;
    }
    if (email.length > 0) {
      data["email"] = email;
    }
    if (lastName.length > 0) {
      data["lastName"] = lastName;
    }
    if (mobileNumber.length > 0) {
      data["mobileNumber"] = mobileNumber;
    }
    return authAxios.patch(`/profile`, data, {
      withCredentials: true,
    });
  }

  updateProfilePassword(oldPassword, newPassword) {
    let data = {};
    data["oldPassword"] = oldPassword;
    data["newPassword"] = newPassword;
    return authAxios.patch(`/profile/change-password`, data, {
      withCredentials: true,
    });
  }

  logout = async () => {
    try {
      await authAxios.get(`logout`, { withCredentials: true });
      window.location.href = "/admin/login";
    } catch (error) {
      console.log(error);
    }
  };
}

export default new AdminService();
