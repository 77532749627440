import React, { Component } from "react";
import styled from "styled-components";
import img from "../images/sprite_3.png";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import clientService from "../services/client.service";

class ClientOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      elementsPerPage: 5,
      data: [],
      totalItems: 0,
      searchFilter: "",
      orders_loaded: false,
    };
  }
  componentDidMount() {
    // console.log(this.state.data);
    this.setState({ is_loaded: false }, () =>
      this.getClientOrders(this.props.clientId)
    );
  }

  getClientOrders = async (id) => {
    const { currentPage, elementsPerPage } = this.state;
    try {
      let response = await clientService.getClientOrders(
        id,
        currentPage,
        elementsPerPage
      );
      this.setState({
        data: response.data.orders,
        totalItems: response.data.total,
        orders_loaded: true,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  render() {
    const {
      orders_loaded,
      data,
      currentPage,
      totalItems,
      elementsPerPage,
    } = this.state;

    return (
      <>
        {orders_loaded && data.length > 0 && (
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h3>Listes des commandes</h3>
              <div className="bloc_header_btns"></div>
            </div>
            <div className="bloc_content table_wrraper">
              <table
                className="table processableTable"
                id="26555_e2d99ecca39dab4b0c717798144e1d8f"
                data-ajax_url="/invoices"
                data-object_table="invoices"
              >
                <thead>
                  <tr className="table_th_orderable">
                    <Th>ID</Th>
                    <Th>Vile de livraison</Th>
                    <Th>Montant total</Th>
                    <Th>Mode de livraison</Th>
                    <Th>Status</Th>
                    <Th>Date de création</Th>
                  </tr>
                </thead>

                {data && (
                  <tbody>
                    {data &&
                      data.map((order, index) => {
                        return (
                          <Tr
                            key={index}
                            onClick={() => {
                              this.props.history.push(
                                `/admin/orders/view/${order.id}`
                              );
                            }}
                          >
                            <Td>{order.id}</Td>

                            <Td>{order.deliveryCity.name}</Td>
                            <Td>{order.originCart.ttc.toFixed(2) + " Dhs"}</Td>
                            <Td>
                              {order.willBeDelivered ? (
                                <Tag_yes>Livrée à l'adresse choisie</Tag_yes>
                              ) : (
                                <Tag_no>Récuperée sur place</Tag_no>
                              )}
                            </Td>
                            <Td>
                              {order.status === "En attente de confirmation" ? (
                                <Tag_waiting_confirmation>
                                  {order.status}
                                </Tag_waiting_confirmation>
                              ) : order.status === "En cours de préparation" ? (
                                <Tag_getting_ready>
                                  {order.status}
                                </Tag_getting_ready>
                              ) : order.status === "En cours de livraison" ? (
                                <Tag_waiting_delivery>
                                  {order.status}
                                </Tag_waiting_delivery>
                              ) : order.status === "Livrée" ? (
                                <Tag_delivered>{order.status}</Tag_delivered>
                              ) : order.status === "Retournée" ? (
                                <Tag_returned>{order.status}</Tag_returned>
                              ) : (
                                ""
                              )}
                            </Td>
                            <Td>
                              {Moment(order.orderDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    {orders_loaded && data.length !== 0 && (
                      <Pagination>
                        <Td colSpan={6} className="table_pagination">
                          <div style={{ display: "inline-block" }}>
                            {currentPage > 1 && (
                              <a
                                className="sprite-table-prev-btn"
                                onClick={() => {
                                  this.setState(
                                    {
                                      currentPage: currentPage - 1,
                                      is_loaded: false,
                                    },
                                    () => {
                                      this.getClientOrders(this.props.clientId);
                                    }
                                  );
                                }}
                              />
                            )}
                            <span className="table_page">{currentPage}</span>
                            {currentPage < totalItems / elementsPerPage && (
                              <a
                                className="sprite-table-next-btn"
                                onClick={() => {
                                  this.setState(
                                    {
                                      currentPage: currentPage + 1,
                                      is_loaded: false,
                                    },
                                    () => {
                                      this.getClientOrders(this.props.clientId);
                                    }
                                  );
                                }}
                              />
                            )}
                          </div>
                        </Td>
                      </Pagination>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </Bloc>
        )}
      </>
    );
  }
}

const Tag_waiting_confirmation = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #ffa902;
  color: #fff;
  display: inline-block;
`;
const Tag_getting_ready = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #ffa902;
  color: #fff;
  display: inline-block;
`;
const Tag_waiting_delivery = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #839dc1;
  color: #fff;
  display: inline-block;
`;
const Tag_delivered = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
const Tag_returned = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_no = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_yes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;

const Th = styled.th`
  cursor: pointer;
  background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
  border-top: 1px solid #8d8d8d;
  wordwrap: "break-word";
  font-weight: 600;
  padding: 6px 5px;
`;
const Tr = styled.tr`
  cursor: pointer;
`;
const Pagination = styled.tr`
  &:hover {
    background-color: #f8f8f8 !important;
  }
`;
const OrderUp = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 6px;
  height: 4px;
  ${"" /* width: 100px;
    height: 100px; */}
  background-position: -213px -98px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -4px;
`;
const OrderDown = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  height: 5px;
  background-position: -195px -98px;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: 4px;
`;
const SearchInputContainer = styled.div`
  height: 38px;
  position: relative;
`;
const SearchIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
`;
const Svg = styled.svg`
  width: 15px;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
`;
const Avatar = styled.a`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #00beb0;
  ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
  display: inline-block;
  float: left;
  ${"" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */}
  transition: all ease-in-out .2s;
  outline: 0;
  text-decoration: none;
  ${"" /* &:hover{
        background-color: #03a79b;
    } */}
  font-size: 13px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 7px;
`;
const TagNo = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const TagYes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
const TagWaiting = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #90cec9;
  color: #fff;
  display: inline-block;
`;

const BlockContact = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #e7055a;
  color: #860b39 !important;
  height: 50px;
  padding-top: 14px;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 10px;
`;
const TitleContact = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;
const BtnContactContainer = styled.div`
  height: 25px;
  float: right;
  display: inline-block;
  vertical-align: top;
`;
export default withRouter(ClientOrdersTable);
