// var api_url = "https://127.0.0.1:8000/api";

// export default api_url;

// "start": "react-scripts start",
    // "build": "react-scripts build",
    // "test": "react-scripts test",
    // "eject": "react-scripts eject"

var api_url = "https://api.monpharmacien.sobrus.ovh/api";

let MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
let TOKEN_URL = "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.monpharmacien.sobrus.ovh/auth/token";

if (process.env.REACT_APP_ENV === "development") {
    api_url = "https://api.monpharmacien.sobrus.ovh/api";
    MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
    TOKEN_URL = "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.monpharmacien.sobrus.ovh/auth/token";
}

if (process.env.REACT_APP_ENV === "production") {
    api_url = "https://api.monpharmacien.sobrus.com/api";
    MANAGE_PROFILE_URL = "https://account.sobrus.com/account";
    TOKEN_URL = "https://api.account.sobrus.com/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.monpharmacien.sobrus.com/auth/token";
}

export default api_url;