import React, { Component } from "react";

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import categoryService from "../services/category.service";
import Header from "../helpers/Header";
import orderService from "../services/order.service";

registerLocale("fr", fr);

class UpdateOrderStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_manager: false,
      account_error: false,
      first_name_error: false,
      last_name_error: false,
      email_error: "",
      category_error: false,

      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      fields: {
        ...this.initialFields,
      },

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.showError = this.showError.bind(this);
  }

  initialFields = {
    status: "",
  };

  getOrderById = async (id) => {
    try {
      let response = await orderService.getOrderById(id);
      let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
      fieldsCopy["status"] = response.data.status;
      this.setState({
        fields: fieldsCopy,
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    this.setState({ fields: fieldsCopy });
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["status"] === "") {
      formIsValid = false;
      errors["status"] = "ce champs ne doit pas être vide";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  updateOrderStatus = async () => {
    try {
      let response = await orderService.updateOrderStatus(
        this.state.idToUpdate,
        this.state.fields
      );
      this.setState({ fields: { ...this.props.initialFields } });
      this.props.history.push(`/admin/orders/view/${this.state.idToUpdate}`, {
        successMessage: " Lstatut de la commande a été modifié avec succès",
      });
    } catch (error) {
      // if (error.response) this.showError(error.response.data.message);
      // console.warn(error);
      if (error.response) {
        if (error.response.data.detail.includes("order_in_menu")) {
          this.showError(
            " L'ordre dans la menu que vous avez saisi existe déjà"
          );
        }
      }
      console.warn(error.response ? error.response.data.detail : error);
    }
  };

  validateAndUpdate = async () => {
    this.setState({ errors: {} }, async () => {
      let valid1 = await this.handleValidation();
      if (valid1) {
        this.updateOrderStatus();
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Commandes - ParaSquare";
    //
    // this.setState({ ready: false }, () =>
    //   setTimeout(() => {
    //     this.getAllCollaborators();
    //     this.getAllDepartments();
    //     this.getAllJobs();
    //     this.setState({ ready: true });
    //   }, 700)
    // );
    //
    const id = this.props.match.params.id;
    if (id) {
      this.getOrderById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    console.log(message);
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 5000);
  }

  render() {
    const { errorMessage, errors, fields, idToUpdate } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          {errorMessage && (
            <FlashMessages successMessage={""} errorMessage={errorMessage} />
          )}
          <PopUp></PopUp>
          <TopOptions
            title={
              "Modifier le statut de la commande N° " + this.state.idToUpdate
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.validateAndUpdate()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>Formulaire de modification</h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>détails de la commande</h3>
                </div>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomSelect
                    error={errors["status"]}
                    errorMessage={errors["status"]}
                    onChange={this.handleChange("status")}
                    selectedValue={fields["status"]}
                    id="status"
                    label="Statut"
                  >
                    <option value="" disabled>
                      Choisissez un status
                    </option>
                    <option value="En attente de confirmation">
                      En attente de confirmation
                    </option>
                    <option value="En cours de préparation">
                      En cours de préparation
                    </option>
                    <option value="En cours de livraison">
                      En cours de livraison
                    </option>
                    <option value="Livrée">Livrée</option>
                    <option value="Retournée">Retournée</option>
                  </CustomSelect>
                </LeftInputContainer>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;
const LeftInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;

const RightInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 51px;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(UpdateOrderStatusPage);
