import React from "react";
import styled from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loader = () => {
    return (
        <LoadingContainer>
            <ScaleLoader height={50} width={10} radius={4} margin={4} color={"#1BC4BB"} />
        </LoadingContainer>
    );
};
const LoadingContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10005;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Loader;