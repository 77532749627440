import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/ads",
  headers: { ...authHeader("hal+") },
});

const authFormDataAxios = axios.create({
  baseURL: api_url + "/ads",
  headers: { ...authHeader(""), "Content-Type": "multipart/form-data" },
});

class AdsService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllAds(
    currentPage,
    limit,
    orderBy,
    order,
    title = "",
    link = "",
    openInNewTab = null,
    ranking = null,
    location = ""
  ) {
    let key = `order[${orderBy}]`;
    let params = {
      page: currentPage,
      _limit: limit,
      [key]: order,
    };
    if (title.length > 0) {
      params["title"] = title;
    }
    if (location.length > 0) {
      params["location"] = location;
    }
    if (link.length > 0) {
      params["link"] = link;
    }
    if (openInNewTab !== null) {
      params["openInNewTab"] = openInNewTab;
    }
    if (ranking) {
      params["ranking"] = ranking;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getAdById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistAd(data) {
    return authFormDataAxios.post("", data, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateAd(id, data) {
    return authFormDataAxios.post(`/${id}?_method=PATCH`, data, {
      withCredentials: true,
    });
  }

  // deleteProduct(id) {
  //   return authAxios.delete(`/${id}`, { withCredentials: true });
  // }
}

export default new AdsService();
