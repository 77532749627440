import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/administration/prescriptions",
});

class PrescriptionService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllPrescriptions(
    currentPage,
    limit,
    orderBy,
    order,
    id = null,
    pharmacyName = "",
    pharmacyCity = "",
    patientName = "",
    status = "",
    updatedAt = ""
  ) {
    let params = {
      page: currentPage,
      limit: limit,
      order: order,
      order_by: orderBy,
    };
    let data = {};
    if (id) {
      data["id"] = { operator: "is", value: id };
    }
    if (pharmacyName.length > 0) {
      data["pharmacyName"] = pharmacyName;
    }
    if (pharmacyCity.length > 0) {
      data["pharmacyCity"] = pharmacyCity ;
    }
    if (patientName.length > 0) {
      data["patientName"] = patientName;
    }
    if (status.length > 0) {
      data["status"] = { operator: "is", value: status };
    }
    if (updatedAt.length > 0) {
      data["updatedAt"] = { operator: "is", value: updatedAt };
    }

    return authAxios.post("", data, { withCredentials: true, params });
  }

  getPrescriptionById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  updatePatient(id, data) {
    return authAxios.patch(`/${id}`, data, { withCredentials: true });
  }

  changePatientStatus(id) {
    return authAxios.patch(`/${id}/change-status`, null, {
      withCredentials: true,
    });
  }
}

export default new PrescriptionService();
