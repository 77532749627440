import axios from "axios";
import api_url from "../config";
import userService from "./user.service";

const API_URL = api_url;

class AuthService {
  login(username, password) {
    return axios.post(
      API_URL + "/login",
      {
        username,
        password,
      },
      { withCredentials: true }
    );
  }

  logout(cb) {
    localStorage.removeItem("user");
    cb();
  }

  // register(username, email, password) {
  //   return axios.post(API_URL + "signup", {
  //     username,
  //     email,
  //     password
  //   });
  // }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  async isAuthenticated () {
    let isAuthenticated = false;
    try {
      await userService.getUserWithoutInterceptor();
      isAuthenticated = true;
    } catch (error) {
      isAuthenticated = false;
    }
    return isAuthenticated;
  }

  refresh = async () => {
    try {
      await axios.get(API_URL + "/token/refresh", {withCredentials: true});
      window.location.reload();
    } catch (error) {
      if(error.response.data.message === "An authentication exception occurred.") {
        window.location.pathname = "/admin/login";
      }
      console.log(error);
      // let response = await userService.logout();
      // window.location.pathname = "/admin/login";
    }
  };
}

export default new AuthService();
