import React, { Component } from "react";
import styled from "styled-components";
import dashboardService from "../services/dashboard.service";
import { PieChart } from "react-minimal-pie-chart";
import Loading from "./loading";
import Loader from "../helpers/Loader";

class DashboardCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      // callClicks: null,
      // cancelledPrescriptions: null,
      // confirmedPatients: null,
      // consultedPharmacies: null,
      // deliveredPrescriptions: null,
      // favoritePharmacies: null,
      // itineraryClicks: null,
      // newPrescriptions: null,
      // nonConfirmedPatients: null,
      // partiallyReadyPrescriptions: null,
      // pendingPrescriptions: null,
      // pharmacies: null,
      // // readyPrescriptions: null,
      // // month: {
      // //   cancelledPrescriptions: null,
      // //   deliveredPrescriptions: null,
      // //   newPrescriptions: null,
      // //   partiallyReadyPrescriptions: null,
      // //   pendingPrescriptions: null,
      // //   readyPrescriptions: null,
      // // },
      // // week: {
      // //   cancelledPrescriptions: null,
      // //   deliveredPrescriptions: null,
      // //   newPrescriptions: null,
      // //   partiallyReadyPrescriptions: null,
      // //   pendingPrescriptions: null,
      // //   readyPrescriptions: null,
      // // },
      // insideWeekChart: "",
      // insideMonthChart: "",
      is_loaded: false,
    };
  }
  componentDidMount() {
    this.setState({ is_loaded: false }, () =>
      setTimeout(() => {
        this.getDashboardInfos();
      }, 700)
    );
  }

  getDashboardInfos = async () => {
    try {
      let response = await dashboardService.getdashboardInfos();
      // let monthSum = 0;
      // for(const key in response.data.month) {
      //   monthSum += response.data.month[key];
      // }
      // let weekSum = 0;
      // for(const key in response.data.week) {
      //   weekSum += response.data.week[key];;
      // };
      this.setState({
        is_loaded: true,
        data: response.data,
        // cancelledPrescriptions: response.data.cancelledPrescriptions,
        // confirmedPatients: response.data.confirmedPatients,
        // consultedPharmacies: response.data.consultedPharmacies,
        // deliveredPrescriptions: response.data.deliveredPrescriptions,
        // favoritePharmacies: response.data.favoritePharmacies,
        // itineraryClicks: response.data.itineraryClicks,
        // newPrescriptions: response.data.newPrescriptions,
        // nonConfirmedPatients: response.data.nonConfirmedPatients,
        // partiallyReadyPrescriptions: response.data.partiallyReadyPrescriptions,
        // pendingPrescriptions: response.data.pendingPrescriptions,
        // pharmacies: response.data.pharmacies,
        // readyPrescriptions: response.data.readyPrescriptions,
        // month: response.data.month,
        // week: response.data.week,
        // insideMonthChart: monthSum + " ordonnances ce mois",
        // insideWeekChart: weekSum + " ordonnances cette semaine",
      });
    } catch (error) {
      console.warn(error);
    }
  };

  render() {
    const {
      data, is_loaded
    } = this.state;
    return (
      <>
      {!is_loaded && <Loader />}
      {is_loaded && <Bloc className="Bloc">
        <Block>
          <div className="bloc_header">
            <h2>Annuaire</h2>
            <div className="bloc_header_btns"></div>
          </div>
          <BlockContent>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Card color="#00C389">
                <Number>
                  {data && (data.pharmacies || data.pharmacies === 0)
                    ? data.pharmacies
                    : "--"}
                </Number>
                <CardTitle>Pharmacies</CardTitle>
              </Card>
              <Card color="#00C389">
                <Number>
                {data && (data.linkedPharmacies || data.linkedPharmacies === 0)
                    ? data.linkedPharmacies
                    : "--"}
                </Number>
                <CardTitle>Pharmacies associées</CardTitle>
              </Card>
              <Card color="#00C389">
                <Number>
                {data && (data.unlinkedPharmacies || data.unlinkedPharmacies === 0)
                    ? data.unlinkedPharmacies
                    : "--"}
                </Number>
                <CardTitle>Pharmacies non associées</CardTitle>
              </Card>
              <Card color="#00C389">
                <Number>{data && (data.favoritePharmacies || data.favoritePharmacies === 0)
                    ? data.favoritePharmacies
                    : "--"}</Number>
                <CardTitle>Pharmacies favorites</CardTitle>
              </Card>
              <Card color="#00C389">
                <Number>
                {data && (data.consultedPharmacies || data.consultedPharmacies === 0)
                    ? data.consultedPharmacies
                    : "--"}
                </Number>
                <CardTitle>Pharmacies consultées</CardTitle>
              </Card>
              <Card color="#FDA729">
                <Number>
                {data && (data.patients || data.patients === 0)
                    ? data.patients
                    : "--"}
                </Number>
                <CardTitle>Patients</CardTitle>
              </Card>
              <Card color="#35BDF3">
                <Number>
                {data && (data.itineraryClicks || data.itineraryClicks === 0)
                    ? data.itineraryClicks
                    : "--"}
                </Number>
                <CardTitle>Clics sur l'itinéraire</CardTitle>
              </Card>
              <Card color="#35BDF3">
                <Number>
                {data && (data.callClicks || data.callClicks === 0)
                    ? data.callClicks
                    : "--"}
                </Number>
                <CardTitle>Nombre d'appels</CardTitle>
              </Card>
              <Card color="#35BDF3">
                <Number>
                {data && (data.consultations || data.consultations === 0)
                    ? data.consultations
                    : "--"}
                </Number>
                <CardTitle>Visites des pages</CardTitle>
              </Card>
            </div>
          </BlockContent>
        </Block>
        {/* <Block>
          <div className="bloc_header">
            <h2>Click'n'Collect</h2>
            <div className="bloc_header_btns"></div>
          </div>
          <BlockContent>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Card2 color="#F1A527">
                <Number>
                  {newPrescriptions || newPrescriptions === 0
                    ? newPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Nouvelles ordonnances</CardTitle>
              </Card2>
              <Card2 color="#3D7DA5">
                <Number>
                  {pendingPrescriptions || pendingPrescriptions === 0
                    ? pendingPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Ordonnances en cours</CardTitle>
              </Card2>
              <Card2 color="#01C187">
                <Number>
                  {(readyPrescriptions || readyPrescriptions === 0) &&
                  (partiallyReadyPrescriptions ||
                    partiallyReadyPrescriptions === 0)
                    ? readyPrescriptions + partiallyReadyPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Ordonnances prêtes</CardTitle>
              </Card2>
              <Card2 color="#91A7AD">
                <Number>
                  {deliveredPrescriptions || deliveredPrescriptions === 0
                    ? deliveredPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Ordonnances livrées</CardTitle>
              </Card2>
              <Card2 color="#F52951">
                <Number>
                  {cancelledPrescriptions || cancelledPrescriptions === 0
                    ? cancelledPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Ordonnances annulées</CardTitle>
              </Card2>
              <Card2 color="#00C389">
                <Number>
                  {cancelledPrescriptions || cancelledPrescriptions === 0
                    ? cancelledPrescriptions +
                      deliveredPrescriptions +
                      newPrescriptions +
                      partiallyReadyPrescriptions +
                      readyPrescriptions +
                      pendingPrescriptions
                    : "--"}
                </Number>
                <CardTitle>Nombre des ordonnances</CardTitle>
              </Card2>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div
                style={{
                  width: "26%",
                  position: "relative",
                }}
              >
                <h3
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {this.state.insideMonthChart}
                </h3>
                {month && (
                  <PieChart
                    data={[
                      {
                        title: "Nouvelles",
                        value: month.newPrescriptions,
                        color: "#F1A527",
                      },
                      {
                        title: "En cours",
                        value: month.pendingPrescriptions,
                        color: "#3D7DA5",
                      },
                      {
                        title: "Prêtes",
                        value:
                          month.readyPrescriptions +
                          month.partiallyReadyPrescriptions,
                        color: "#01C187",
                      },
                      {
                        title: "Livrées",
                        value: month.deliveredPrescriptions,
                        color: "#91A7AD",
                      },
                      {
                        title: "Annulées",
                        value: month.cancelledPrescriptions,
                        color: "#F52951",
                      },
                    ]}
                    segmentsShift={0.5}
                    radius={PieChart.defaultProps.radius - 6}
                    lineWidth={30}
                    segmentsStyle={{
                      transition: "stroke .3s",
                      cursor: "pointer",
                    }}
                    // segmentsShift={(index) => (index === selected ? 6 : 1)}
                    animate
                    // label={({ dataEntry }) => dataEntry.value}
                    // labelPosition={70}
                    // labelStyle={{
                    //   fill: "#fff",
                    //   opacity: 0.75,
                    //   pointerEvents: "none",
                    // }}
                  />
                )}
              </div>
              <div style={{ width: "26%", position: "relative" }}>
                <h3
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {this.state.insideWeekChart}
                </h3>
                {week && (
                  <PieChart
                    data={[
                      {
                        title: "Nouvelles",
                        value: week.newPrescriptions,
                        color: "#F1A527",
                      },
                      {
                        title: "En cours",
                        value: week.pendingPrescriptions,
                        color: "#3D7DA5",
                      },
                      {
                        title: "Prêtes",
                        value:
                          week.readyPrescriptions +
                          week.partiallyReadyPrescriptions,
                        color: "#01C187",
                      },
                      {
                        title: "Livrées",
                        value: week.deliveredPrescriptions,
                        color: "#91A7AD",
                      },
                      {
                        title: "Annulées",
                        value: week.cancelledPrescriptions,
                        color: "#F52951",
                      },
                    ]}
                    radius={PieChart.defaultProps.radius - 6}
                    lineWidth={60}
                    segmentsStyle={{
                      transition: "stroke .3s",
                      cursor: "pointer",
                    }}
                    paddingAngle={5}
                    // segmentsShift={(index) => (index === selected ? 6 : 1)}
                    animate
                    label={({ dataEntry }) => dataEntry.value}
                    labelPosition={70}
                    labelStyle={{
                      fill: "#fff",
                      opacity: 0.75,
                      pointerEvents: "none",
                    }}
                  />
                )}
              </div>
            </div>
          </BlockContent>
        </Block> */}
      </Bloc>}
      </>
    );
  }
}

const Bloc = styled.div`
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
`;

const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const Card = styled.div`
  width: 18%;
  height: 150px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  border: 1px solid #d0d0d0;
  margin: 10px;
`;

const Card2 = styled.div`
  width: 16%;
  height: 150px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  border: 1px solid #d0d0d0;
  margin: 10px;
`;

const Number = styled.span`
  font-size: 45px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 12px;
`;

const CardTitle = styled.span`
  font-size: 18px;
  color: #fff;
`;

export default DashboardCards;
