import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import userService from "../services/user.service";
import HeaderItem from "./HeaderItem";
import adminService from "../services/admin.service";
import Loader from "./Loader";

class Header extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.profileRef = React.createRef();
    this.logoutRef = React.createRef();
    this.state = {
      selected: 1,
      admin: null,
      loadingAdmin: true,
      userParams: "none",
      loading: true
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleUserParams = () => {
    if (this.state.userParams === "none") {
      this.setState({ userParams: "block" });
    } else {
      this.setState({ userParams: "none" });
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && (!this.wrapperRef.current.contains(event.target) && !this.profileRef.current.contains(event.target) && !this.logoutRef.current.contains(event.target))) {
      this.setState({ userParams: "none" });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getCurrentAdmin = async () => {
    try {
      let response = await adminService.getUser();
      this.setState({ admin: response.data, loadingAdmin: false, loading: false });
    } catch (error) {
      console.warn(error);
    }
  };

  componentWillMount() {
    setTimeout(() => {
      this.getCurrentAdmin();
    }, 700);
  } 

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    
  }

  // logout = () => {
  //   authService.logout(() => {
  //     window.location.reload();
  //   });
  // };

  render() {
    // console.log(this.props.selected)
    const { admin, loadingAdmin, loading } = this.state;

    return (
      loading ? <Loader></Loader> : <Container className="Container">
        <Wrapper className="Wrapper">
          <Link to={"/"}>
            <Logo className="Logo">
              <Strong>MonPharmacien </Strong>- BackOffice
            </Logo>
          </Link>
          <Nav>
            <Ul>
              <HeaderItem
                to={"/admin/dashboard"}
                itemTitle="Tableau de bord"
                {...this.props}
              />
              {/* <HeaderItem
                to={"/admin/patients"}
                itemTitle="Patients"
                {...this.props}
              /> */}
              <HeaderItem
                to={"/admin/pharmacies"}
                itemTitle="Pharmacies"
                {...this.props}
              />
              {/* <HeaderItem
                to={"/admin/prescriptions"}
                itemTitle="Ordonnances"
                {...this.props}
              /> */}
              <HeaderItem
                to={"/admin/settings"}
                itemTitle="Paramètres"
                {...this.props}
              />
              {/* <HeaderItem
                to={"/admin/categories"}
                itemTitle="Catégories"
                {...this.props}
              >
                <Sub_menu minWidth="140px">
                  <Bloc_2>
                    <ul>
                      <Link to={"/admin/categories/sub-categories"}>
                        <Item>Sous catégories</Item>
                      </Link>
                      <Link to={"/admin/categories/sub-sub-categories"}>
                        <Item>Sous sous catégories</Item>
                      </Link>
                    </ul>
                  </Bloc_2>
                </Sub_menu>
              </HeaderItem> */}
            </Ul>
          </Nav>
          <UserParam
            onClick={() => this.toggleUserParams()}
            ref={this.wrapperRef}
          >
            <Avatar href="#" title="profil">
              {!loadingAdmin && admin ? admin.firstName.charAt(0).toUpperCase() + admin.lastName.charAt(0).toUpperCase() : ""}
            </Avatar>
            <UserName>
              {loadingAdmin === false && admin
                ? admin.firstName + " " + admin.lastName 
                : "Chargement..."}
            </UserName>
            <span className="profil_arrow">.</span>
            <SubMenuUserParam userParams={this.state.userParams}>
              <AvatarUserParam>
                <svg
                  width={26}
                  height={26}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user"
                  className="svg-inline--fa fa-user fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="#3c3d3d"
                    d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                  ></path>
                </svg>
              </AvatarUserParam>
              <NameUserParam>
                {loadingAdmin === false && admin ? admin.name : "chargement..."}
              </NameUserParam>
              <InfosList>
                <ItemList
                  onClick={() => {
                    this.props.history.push("/admin/settings/profile");
                  }}
                  ref={this.profileRef}
                >
                  Mon profil
                </ItemList>
                <LogOutItemList ref={this.logoutRef} onClick={() => adminService.logout()}>
                  <LogOutText>Déconnexion</LogOutText>
                  <LogOutIcon
                    width={14}
                    height={14}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="power-off"
                    className="svg-inline--fa fa-power-off fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#F84B4B"
                      d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
                    ></path>
                  </LogOutIcon>
                </LogOutItemList>
              </InfosList>
            </SubMenuUserParam>
          </UserParam>
        </Wrapper>
      </Container>
    );
  }
}
const LogOutIcon = styled.svg`
  float: right;
  margin-top: 3px;
`;
const LogOutText = styled.span`
  font-family: OpenSans;
  font-weight: 600;
  color: #f84b4b;
  float: left;
`;
const Container = styled.header`
  height: 50px;
  background: #00C389;
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
  font: 12px "Open Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  box-sizing: border-box;
  position: relative;
`;
const Wrapper = styled.div`
  width: 96.2%;
  margin: auto;
`;
const Logo = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  line-height: 50px;
  float: left;
  outline: 0;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
`;
const Strong = styled.strong`
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
`;
const Nav = styled.nav`
  display: inline-block;
  vertical-align: top;
  margin-left: 1.8%;
`;
const Ul = styled.ul`
  list-style: none;
`;
const Li = styled.li`
  display: inline-block;
`;
const UserParam = styled.div`
  padding: 7px 10px 0 20px;
  float: right;
  cursor: pointer;
  border-left: 1px solid #81cacb;
  height: 50px;
`;
const SubMenuUserParam = styled.div`
  ${"" /* min-width: 540px; */}
  min-width: 273px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #c3c3c3;
  background-color: #ffffff;
  height: 242px;
  ${"" /* height: 330px; */}
  z-index:99;
  position: absolute;
  top: 50px;
  right: 0;
  display: ${(props) => props.userParams};
  padding-top: 17px;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    right: 34px;
    top: -6px;
    transform: rotate(45deg);
    ${"" /* border-top: solid 1px #c3c3c3;
    border-left: solid 1px #c3c3c3; */}
  }
`;

const AvatarUserParam = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 600px;
  border: solid 1px #969696;
  background-color: #cbcbcb;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 13px;
`;

const NameUserParam = styled.p`
  font-family: OpenSans;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #494949;
  text-align: center;
  margin-top: 11px;
`;

const ReferenceUserParam = styled.p`
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
  text-align: center;
  margin-top: 6px;
`;
const InfosList = styled.div`
  padding-top: 14px;
`;
const ItemList = styled.div`
  border-top: solid 1px #f5f5f5;
  height: 45px;
  width: 201px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans;
  font-weight: 600;
  color: #494949;
  padding-top: 13px;
`;
const LogOutItemList = styled.div`
  border-top: solid 1px #f5f5f5;
  height: 38px;
  width: 208px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
`;

const Avatar = styled.a`
  height: 34px;
  width: 34px;
  border-radius: 3px;
  background-color: #fff;
  ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
  display: inline-block;
  float: left;
  ${"" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */}
  transition: all ease-in-out .2s;
  outline: 0;
  text-decoration: none;
  ${"" /* &:hover{
        background-color: #03a79b;
    } */}
  font-size: 13px;
  font-weight: 900;
  color: #00C389;
  text-align: center;
  padding-top: 7px;
`;
const UserName = styled.p`
  margin: 6px 10px 0 10px;
  float: left;
  color: #fff;
  font-weight: bold;
  padding-top: 2px;
`;
const Sub_menu = styled.div`
  ${"" /* min-width: 540px; */}
  min-width: ${(props) => props.minWidth};
  background: #fff;
  border: 1px solid #c3c3c3;
  ${"" /* height:232px; */}
  ${"" /* height: 289px; */}
    display:none;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-left-color: #00C389;
  border-left-width: 5px;
`;
const Bloc_2 = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
  padding-left: 7px;
  padding-top: 7px;
  border-right: solid 1px #f5f5f5;
`;

const Item = styled.li`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "#494949")};
  padding-bottom: 12px;
  padding-right: 5px;
  cursor: pointer;
  &:hover {
    color: #00C389;
  }
`;
const mapStateToProps = (state) => {
  return {
    selected: state.selectHeaderItem.selcted,
    totalUnseenNotifs:
      state.changeTotalUnseenNotifications.totalUnseenNotifications,
  };
};
export default connect(mapStateToProps)(withRouter(Header));
