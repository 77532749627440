import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import Header from "../helpers/Header";
import prescriptionsService from "../services/prescriptions.service";

class DetailsPrescriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      is_loaded: false,
      demands_loaded: false,
      prescriptionId: null,
      prescription: null,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 7 });
    document.title = "Ordonnances - MonPharmacien";
    const state = this.props.location.state;
    if (state !== undefined) {
      this.showSuccess(state.successMessage);
    }
    this.getPrescriptionById(id);
  }

  getPrescriptionById = async (id) => {
    try {
      let response = await prescriptionsService.getPrescriptionById(id);
      this.setState({
        prescription: response.data,
        is_loaded: true,
        prescriptionId: id,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  showSuccess(message) {
    this.setState({ successMessage: message });
    setTimeout(() => {
      this.setState({ successMessage: "" });
    }, 5000);
  }

  showError(message) {
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 5000);
  }

  render() {
    const {
      prescription,
      successMessage,
      errorMessage,
      is_loaded,
    } = this.state;
    const id = this.props.match.params.id;

    const STATUS_COLOR = {
      "Nouvelle": "#F1A527",
      "En cours": "#3D7DA5",
      "Prête": "#01C187",
      "Prête partiellement": "#01C187",
      "Livrée": "#91A7AD",
      "Annulée": "#F52951"
    }

    return (
      <>
        <Header></Header>
        <MainContainer className="MainContainer">
          <FlashMessages
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
          <TopOptions title={prescription && "Ordonnance N°" + prescription.id}>
            {/* {patient && (
              <>
                <button
                  className={patient && patient.status ? "btn_red" : "btn_bleu"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      window.confirm(
                        patient.status ? "Voulez-vous vraiment désactiver ce patient ?" : "Voulez-vous vraiment réactiver ce patient ?"
                      )
                    )
                      patient.status ? this.changePatientStatus(id, " Ce patient a été désactivé avec succès") : this.changePatientStatus(id, " Ce patient a été réactivé avec succès");
                  }}
                >
                  {patient && patient.status ? "Désactiver" : "Réactiver"}
                </button>
                <button
                  className="btn_bleu"
                  onClick={() => {
                    this.props.history.push(`/admin/patients/update/${id}`);
                  }}
                >
                  Modifier
                </button>
                <button
                  className="btn_red"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      window.confirm(
                        "Vous allez envoyer un email de réintialisation de password à ce patient, êtes-vous sûr ?"
                      )
                    )
                      this.reinitializePassword(patient.email)
                  }}
                >
                  Réinitialiser le mot de de passe
                </button>
              </>
            )} */}
          </TopOptions>
          <Content className="Content">
            <LeftBlockContainer>
              <Block>
                <div className="bloc_header">
                  <h2>Détails</h2>
                  <div className="bloc_header_btns"></div>
                </div>
                <BlockContent>
                  <TitleContainer>
                    <Title>Informations générales</Title>
                    <Bar></Bar>
                  </TitleContainer>
                  <FieldsContainer>
                    <Row>
                      <ColOne>
                        <FieldLabel>Pharmacie</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {prescription.pharmacy
                              ? prescription.pharmacy.name
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Statut</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>{prescription.status}</FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row>
                      <ColOne>
                        <FieldLabel>Ville de la pharmacie</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {prescription.pharmacy
                              ? prescription.pharmacy.city.name
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Prénom du patient</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {prescription.patient
                              ? prescription.patient.firstName
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row>
                      <ColOne>
                        <FieldLabel>
                          Numéro de téléphone de la pharmacie
                        </FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {prescription.pharmacy &&
                            prescription.pharmacy.mobileNumber
                              ? prescription.pharmacy.mobileNumber
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Numéro de téléphone du patient</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {prescription.patient &&
                            prescription.patient.mobileNumber
                              ? prescription.patient.mobileNumber
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row></Row>
                  </FieldsContainer>
                </BlockContent>
              </Block>
            </LeftBlockContainer>
            <RightBlockContainer>
              <div className="bloc">
                <div
                  style={{
                    backgroundColor:
                      prescription && prescription.status
                        ? STATUS_COLOR[prescription.status]
                        : "#fff",
                  }}
                  className={"bloc_content devis_statut ds_complete"}
                >
                  <h3>{prescription && prescription.status}</h3>
                </div>
              </div>

              <div className="traceability-status-container">
                <TraceabilityBlockTitle bgColor="#fff">
                  Historique des status
                </TraceabilityBlockTitle>
                <ul>
                  {prescription &&
                    prescription.statusHistory &&
                    prescription.statusHistory.map((item, index) => (
                      <li
                        key={"statushistory" + index}
                        className="traceability-status-li"
                      >
                        <span style={{color: STATUS_COLOR[item.status]}}>{item.status}</span>
                        <span>
                          {item.actionDate &&
                            moment(item.actionDate).format("YYYY-MM-DD HH:mm")}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <TraceabilityBlock>
                <TraceabilityBlockTitle>
                  Informations de traçabilité
                </TraceabilityBlockTitle>
                <ul>
                  {/* <Li>
                  <ColOneLi>Crée par</ColOneLi>
                  <ColOneLi>
                    {hardware &&
                      hardware.createdBy.firstName +
                        " " +
                        hardware.createdBy.lastName}
                  </ColOneLi>
                </Li> */}
                  <Li>
                    <ColOneLi>créée le</ColOneLi>
                    <ColOneLi>
                      {prescription &&
                        moment(prescription.createdAt).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                    </ColOneLi>
                  </Li>
                  <Li>
                    <ColOneLi>modifiée le</ColOneLi>
                    <ColOneLi>
                      {prescription &&
                        prescription.updatedAt &&
                        moment(prescription.updatedAt).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                    </ColOneLi>
                  </Li>
                </ul>
              </TraceabilityBlock>
            </RightBlockContainer>
          </Content>
        </MainContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(DetailsPrescriptionPage);

const ErrorContainer = styled.div``;
const ErrorText = styled.div`
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
`;
const FormRow = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;
const Col12 = styled.div`
  width: calc(96% + 51px);
  height: ${(props) => (props.height ? props.height : "41px")};
  display: inline-block;
  vertical-align: top;
`;
const MainContainer = styled.div`
  height: 100%;
`;
const LeftBlockContainer = styled.div`
  width: 74%;
  padding-bottom: 10px;
  display: inline-block;
  vertical-align: top;
`;
const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 50px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  background: #fff;
  color: #494949;
  position: absolute;
  top: -10px;
  left: 0;
  padding-right: 21px;
`;
const FieldsContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 29px;
  margin-bottom: 30px;
`;
const Content = styled.div`
  margin-top: 10px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
`;
const Row = styled.div`
  display: block;
  margin-bottom: 5px;
  width: 100%;
`;
const FieldLabel = styled.p`
  display: block;
  color: grey;
  font-weight: 600;
`;
const FieldValueGreen = styled.p`
  display: block;
  color: #00beb0;
  font: 12px "Open Sans", sans-serif;
`;
const FieldValue = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 700;
`;
const ColOne = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
`;
const ColTwo = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
  margin-left: 39px;
`;
const BlockContact = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #e7055a;
  color: #860b39 !important;
  height: 50px;
  padding-top: 14px;
  padding-left: 9px;
  padding-right: 9px;
`;
const TitleContact = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;

const BtnContactContainer = styled.div`
  height: 25px;
  float: right;
  display: inline-block;
  vertical-align: top;
`;

const RightBlockContainer = styled.div`
  width: 24%;
  display: inline-block;
  vertical-align: top;
  margin-left: 27px;
`;
const TraceabilityBlock = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
  -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
  box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const TraceabilityBlockTitle = styled.p`
  padding: 10px;
  background: #f0f0f0;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  width: 100%;
`;
const Li = styled.li`
  border-top: 1px solid #8d8d8d;
  padding: 6px 5px;
  cursor: pointer;
  &:hover {
    background: #00beb0;
    > p {
      color: #fff;
    }
  }
`;
const ColOneLi = styled.p`
  font-weight: 600;
  font: 12px "Open Sans", sans-serif;
  color: #494949;
  height: 100%;
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;
const TitleContainer = styled.div`
  position: relative;
`;
const Bar = styled.div`
  width: 98%;
  height: 1px;
  background: #8d8d8d;
  margin-top: 17px;
`;

const AvatarContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #00beb0;
  background-color: #fbfbfb;
  position: absolute;
  left: 20px;
  top: 10px;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #00beb0;
`;

const Tag_no = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_yes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
