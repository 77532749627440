import React, { Component } from "react";
import styled from "styled-components";
import SettingsLayout from "./SettingsLayout";
import { connect } from "react-redux";
import Header from "../helpers/Header";
import CustomInput from "../components/CustomInput";
import adminService from "../services/admin.service";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    const state = this.props.location.state;
    this.state = {
      successMessage: "",
      errorMessage: "",
      error: false,
      success: false,
      componentState: state !== undefined ? state : undefined,
      errors: {},
      ready: false,
      fields: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      },
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 9 });
    document.title = "Changer mon mot de passe - MonPharmacien";
  }

  showNotification = (type, message) => {
    if (type === "success") {
      this.setState({ success: true, successMessage: message }, () => {
        setTimeout(
          () => this.setState({ success: false, successMessage: "" }),
          5000
        );
      });
    }
    if (type === "error") {
      this.setState({ error: true, errorMessage: message }, () => {
        setTimeout(
          () => this.setState({ error: false, errorMessage: "" }),
          5000
        );
      });
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    this.setState({ fields: fieldsCopy });
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["oldPassword"] === "") {
      formIsValid = false;
      errors["oldPassword"] = "Veuillez renseigner ce champ";
    } else if (fields["oldPassword"].length < 8) {
      formIsValid = false;
      errors["oldPassword"] = "Minimum 8 caractères";
    }

    if (fields["newPassword"] === "") {
      formIsValid = false;
      errors["newPassword"] = "Veuillez renseigner ce champ";
    } else if (fields["newPassword"].length < 8) {
      formIsValid = false;
      errors["newPassword"] = "Minimum 8 caractères";
    }

    if (fields["newPasswordConfirmation"] === "") {
      formIsValid = false;
      errors["newPasswordConfirmation"] = "Veuillez renseigner ce champ";
    } else if (
      fields["newPasswordConfirmation"].length < 8 ||
      fields["newPasswordConfirmation"] !== fields["newPassword"]
    ) {
      formIsValid = false;
      errors["newPasswordConfirmation"] =
        "Votre confirmation du mot de passe n'est pas identique à votre nouveau mot de passe";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  changePassword = async () => {
    try {
      await adminService.updateProfilePassword(
        this.state.fields["oldPassword"],
        this.state.fields["newPassword"]
      );
      this.props.history.push(`/admin/settings/profile`, {
        successMessage: " Votre mot de passe a été modifié avec succès",
      });
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "wrong old password") {
          let errors = {};
          errors["oldPassword"] = "ancien mot de passe incorrect";
          this.setState({ errors: { ...this.state.errors, ...errors } });
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  validateAndSavePassword = () => {
    this.setState({ errors: {} }, async () => {
      let valid = await this.handleValidation();
      if (valid) {
        this.changePassword();
      }
    });
  };

  render() {
    const { fields, errors } = this.state;
    return (
      <>
        <Header></Header>
        <SettingsLayout
          title="Changer mon mot de passe"
          topButtonOptions={{ btnText: "Sauvegarder" }}
          topButtonMethod={this.validateAndSavePassword}
          active={2}
          open={1}
        >
          <FirstBlock>
            <div className="bloc_header">
              <h2>
                Paramètres {">"} Mes paramètres {">"} Changer mon mot de passe
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <BlockContent>
              <BlockContentTitle>
                Cette page vous permet de modifier votre mot de passe.
              </BlockContentTitle>
            </BlockContent>
          </FirstBlock>
          <Block>
            <div className="bloc_header">
              <h2>Formulaire de modification</h2>
              <div className="bloc_header_btns"></div>
            </div>
            <BlockContent>
              <FieldsContainer>
                <Row>
                  <ColOne>
                    <CustomInput
                      error={errors["oldPassword"]}
                      errorMessage={errors["oldPassword"]}
                      onChange={this.handleChange("oldPassword")}
                      value={fields["oldPassword"]}
                      id="oldPassword"
                      label="Ancien mot de passe *"
                      type="password"
                    ></CustomInput>
                  </ColOne>
                </Row>
                <Row>
                  <ColOne>
                    <CustomInput
                      error={errors["newPassword"]}
                      errorMessage={errors["newPassword"]}
                      onChange={this.handleChange("newPassword")}
                      value={fields["newPassword"]}
                      id="newPassword"
                      label="Nouveau mot de passe *"
                      type="password"
                    ></CustomInput>
                  </ColOne>
                </Row>
                <Row>
                  <ColOne>
                    <CustomInput
                      error={errors["newPasswordConfirmation"]}
                      errorMessage={errors["newPasswordConfirmation"]}
                      onChange={this.handleChange("newPasswordConfirmation")}
                      value={fields["newPasswordConfirmation"]}
                      id="newPasswordConfirmation"
                      label="Confirmation du mot de passe *"
                      type="password"
                    ></CustomInput>
                  </ColOne>
                </Row>
              </FieldsContainer>
            </BlockContent>
          </Block>
        </SettingsLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};

export default connect(mapStateToProps)(ChangePassword);

const FirstBlock = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const BlockContentTitle = styled.p`
  padding: 10px 0;
  background: #f6f6f6;
  border: 2px solid #b2b2b2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Row = styled.div`
  display: block;
  margin-bottom: 20px;
  width: 100%;
`;

const ColOne = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;
const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  ${"" /* padding-bottom: 20px; */}
  margin-bottom: 10px;
`;
const FieldsContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 29px;
  margin-bottom: 30px;
`;
