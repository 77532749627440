import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/brands",
  headers: authHeader("hal+"),
});

class BrandService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (401 === error.response.status) {
          // await authService.refresh(() => {
          //   window.location.reload();
          // });
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllBrands( currentPage, limit, orderBy, order, name = "", totalNumberOfProducts = null) {
    let key = `order[${orderBy}]`;
    let params = {
      "page": currentPage,
      "_limit": limit,
      [key]: order
    };
    if (name.length > 0) {
      params["name"] = name;
    }
    if (totalNumberOfProducts) {
      params["totalNumberOfProducts"] = totalNumberOfProducts;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getBrandById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistBrand(data) {
    return authAxios.post("", data, { withCredentials: true });
  }

  updateBrand(id, data) {
    return authAxios.patch(`/${id}`, data, {
      withCredentials: true,
      headers: { "content-type": "application/merge-patch+json" },
    });
  }

  deleteBrand(id) {
    return authAxios.delete(`/${id}`, { withCredentials: true });
  }
}

export default new BrandService();
