import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/shipping_costs",
  headers: authHeader("hal+"),
});

class ShippingCostService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllShippingCosts( currentPage, limit, orderBy, order, id = null, city = "", price = null, freeFrom = null) {
    let key = `order[${orderBy}]`;
    let params = {
      "page": currentPage,
      "_limit": limit,
      [key]: order
    };
    if (id) {
      params["id"] = id;
    }
    if (city.length > 0) {
      params["city.name"] = city;
    }
    if (price) {
      params["price"] = price;
    }
    if (freeFrom) {
      params["freeFrom"] = freeFrom;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getShippingCostById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistShippingCost(data) {
    return authAxios.post("", data, { withCredentials: true });
  }

  updateShippingCost(id, data) {
    return authAxios.patch(`/${id}`, data, {
      withCredentials: true,
      headers: { "content-type": "application/merge-patch+json" },
    });
  }

  deleteShippingCost(id) {
    return authAxios.delete(`/${id}`, { withCredentials: true });
  }
}

export default new ShippingCostService();
