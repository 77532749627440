import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

class SideBarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }
  render() {
    const {
      open,
    } = this.state;

    return (
      <SideBarBlock>
        <ul>
          <Li background={open === 1 ? "#f6f6f6" : "#fff"}>
            <LiContainer onClick={() => this.setState({ open: 1 })}>
              <ColOneLi>Mes paramètres</ColOneLi>
              <AngleIcon>
                {open === 1 ? (
                  <Svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-down"
                    className="svg-inline--fa fa-angle-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="#494949"
                      d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                    ></path>
                  </Svg>
                ) : (
                  <Svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-up"
                    className="svg-inline--fa fa-angle-up fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="#494949"
                      d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                    ></path>
                  </Svg>
                )}
              </AngleIcon>
            </LiContainer>
            <UnderList display={open === 1 ? "block" : "none"}>
              <UnderListLi
                className={this.props.active === 1 ? "li-active" : ""}
                onClick={() => this.props.history.push("/admin/settings/profile")}
              >
                Profil
              </UnderListLi>
              <UnderListLi
                className={this.props.active === 2 ? "li-active" : ""}
                onClick={() => this.props.history.push("/admin/settings/profile/change-password")}
              >
                Modifier mon mot de passe
              </UnderListLi>
            </UnderList>
          </Li>
        </ul>
      </SideBarBlock>
    );
  }
}

export default withRouter(SideBarMenu);

const MainContainer = styled.div`
  height: 100%;
`;
const LeftBlockContainer = styled.div`
  width: 74%;
  padding-bottom: 10px;
  display: inline-block;
  vertical-align: top;
`;
const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 50px;
  margin-bottom: 10px;
`;
const FirstBlock = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  background: #fff;
  color: #494949;
  position: absolute;
  top: -10px;
  left: 0;
  padding-right: 21px;
`;
const FieldsContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 29px;
  margin-bottom: 30px;
`;
const Content = styled.div`
  margin-top: 10px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
`;
const Row = styled.div`
  display: block;
  margin-bottom: 5px;
  width: 100%;
`;
const FieldLabel = styled.p`
  display: block;
  color: grey;
  font-weight: 600;
`;
const FieldValueGreen = styled.p`
  display: block;
  color: #00beb0;
  font: 12px "Open Sans", sans-serif;
`;
const FieldValue = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 700;
`;
const Col1 = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
`;
const Col2 = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
  margin-left: 39px;
`;
const BlockContact = styled.div`
  margin-top: 10px;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #e7055a;
  color: #860b39 !important;
  height: 50px;
  padding-top: 14px;
  padding-left: 9px;
  padding-right: 9px;
`;
const TitleContact = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;

const BtnContactContainer = styled.div`
  height: 25px;
  float: right;
  display: inline-block;
  vertical-align: top;
`;

const CreateContactBtn = styled.button`
  outline: none;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  background: #fff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #494949;
  border: 1px solid #f0f0f0;
  height: 100%;
  padding: 0 22px;
  font-size: 12px;
  &:hover {
    border: 1px solid #b2b2b2;
  }
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 10px;
`;
const RightBlockContainer = styled.div`
  width: 24%;
  display: inline-block;
  vertical-align: top;
  margin-right: 27px;
`;
const CommandBlock = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
  -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  padding: 10px;
`;

const CommandBlockTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const CommandBlockTotal = styled.div`
  margin-top: 13px;
`;
const Command_block_icon = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 3px;
  background-color: #ef396f;
  margin-right: 6px;
  display: inline-block;
  position: relative;
`;
const Img = styled.img`
  position: absolute;
  top: 5px;
  left: 7px;
`;
const CommandBlockValue = styled.div`
  display: inline-block;
  vertical-align: top;
  background-color: #f6f6f6;
  width: 88%;
  height: 29px;
  border-radius: 3px;
  margin-left: 2px;

  color: #4a4a4a;
  font-family: open Sans;
  font-weight: 400;
  font-size: 13px;
  padding-top: 3px;
  padding-left: 8px;
`;
const Strong = styled.strong`
  font-weight: 700;
  color: #4a4a4a;
  font-family: open Sans;
  font-size: 13px;
`;

const AddBtn = styled.button`
  margin-top: 13px;
  height: 25px;
  width: 100%;
  outline: none;
  background: #fff;
  border-radius: 2px;
  color: #494949;
  border: 1px solid #f0f0f0;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    border: 1px solid #b2b2b2;
  }
`;
const SideBarBlock = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
  -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
  box-shadow: 0 1px 3px 0 #bcbcbc;
`;

const Li = styled.li`
  cursor: pointer;
  position: relative;
  background: ${(props) => props.background};
`;
const ColOneLi = styled.p`
  font-weight: 600;
  font: 12px "Open Sans", sans-serif;
  color: #00beb0;
  height: 100%;
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;
const AngleIcon = styled.div`
  position: absolute;
  width: 13px;
  top: 10px;
  right: 6px;
  height: 10px;
`;
const Svg = styled.svg`
  width: 12px;
`;

const UnderList = styled.ul`
  width: 100%;
  padding-bottom: 4px;
  background: #fff;
  display: ${(props) => props.display};
`;

const LiContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 10px 5px;
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
  }
`;
const UnderListLi = styled.li`
  display: block;
  padding: 2px 9px;
  color: #00beb0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 3px;
  &:hover {
    color: #494949;
  }
  &.active {
    color: #494949;
  }
`;

const BlockContentTitle = styled.p`
  padding: 10px 0;
  background: #f6f6f6;
  border: 2px solid #b2b2b2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const Bar = styled.div`
  width: 98%;
  height: 1px;
  background: #8d8d8d;
  margin-top: 17px;
`;
