import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/sub_categories",
  headers: authHeader("hal+"),
});

class SubCategoryService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllSubCategories(
    currentPage,
    limit,
    orderBy,
    order,
    name = "",
    categoryId = null
  ) {
    let key = `order[${orderBy}]`;
    let params = {
      page: currentPage,
      _limit: limit,
      [key]: order,
    };
    if (name.length > 0) {
      params["name"] = name;
    }
    if (categoryId) {
      params["category.id"] = categoryId;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getSubCategoryById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistSubCategory(data) {
    return authAxios.post("", data, { withCredentials: true });
  }

  updateSubCategory(id, data) {
    return authAxios.patch(`/${id}`, data, {
      withCredentials: true,
      headers: { "content-type": "application/merge-patch+json" },
    });
  }

  deleteSubCategory(id) {
    return authAxios.delete(`/${id}`, { withCredentials: true });
  }
}

export default new SubCategoryService();
