import React, { Component } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import adminService from "../services/admin.service";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      admin: null,
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 7 });
    document.title = "Profil - MonPharmacien";
    const state = this.props.location.state;
    if (state !== undefined) {
      this.showSuccess(state.successMessage);
    }
    this.getCurrentAdmin();
  }

  getCurrentAdmin = async () => {
    try {
      let response = await adminService.getUser();
      this.setState({
        admin: response.data,
        is_loaded: true,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  showSuccess(message) {
    this.setState({ successMessage: message });
    setTimeout(() => {
      this.setState({ successMessage: "" });
    }, 3000);
  }

  render() {
    const { admin, is_loaded } = this.state;

    return (
      <Block>
        <div className="bloc_header">
          <h2>Détails</h2>
          <div className="bloc_header_btns"></div>
        </div>
        <BlockContent>
          <div
            className="bloc_h3"
            style={{
              width: "98.5%",
              marginTop: "10px",
              marginBottom: "30px",
              textTransform: "uppercase",
            }}
          >
            <span></span>
            <h3>Informations principales</h3>
          </div>
          <FieldsContainer>
            <Row>
              <ColOne>
                <FieldLabel>Nom</FieldLabel>
                {is_loaded ? (
                  <FieldValue>{admin.lastName}</FieldValue>
                ) : (
                  <ContentLoader width="160" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                  </ContentLoader>
                )}
              </ColOne>
              <ColTwo>
                <FieldLabel>Email</FieldLabel>
                {is_loaded ? (
                  <FieldValue>{admin.email}</FieldValue>
                ) : (
                  <ContentLoader width="160" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                  </ContentLoader>
                )}
              </ColTwo>
            </Row>
            <Row>
              <ColOne>
                <FieldLabel>Prénom</FieldLabel>
                {is_loaded ? (
                  <FieldValue>{admin.firstName}</FieldValue>
                ) : (
                  <ContentLoader width="160" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                  </ContentLoader>
                )}
              </ColOne>
              <ColTwo>
                <FieldLabel>Numéro de téléphone</FieldLabel>
                {is_loaded ? (
                  <FieldValue>
                    {admin.mobileNumber ? admin.mobileNumber : "--"}
                  </FieldValue>
                ) : (
                  <ContentLoader width="160" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                  </ContentLoader>
                )}
              </ColTwo>
            </Row>
          </FieldsContainer>
        </BlockContent>
      </Block>
    );
  }
}

// const Bloc = styled.div`
//   box-shadow: 0 1px 3px 0 #bcbcbc;
//   width: 100%;
//   position: relative;
//   margin: auto;
//   margin-bottom: 60px;
//   margin-top: 10px;
// `;

const FieldValueGreen = styled.p`
  display: block;
  color: #785ea8;
  font: 12px "Open Sans", sans-serif;
`;

const Row = styled.div`
  display: block;
  margin-bottom: 5px;
  width: 100%;
`;

const FieldLabel = styled.p`
  display: block;
  color: grey;
  font-weight: 600;
`;

const FieldsContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 29px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 50px;
`;

const ColOne = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
`;
const ColTwo = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
  margin-left: 39px;
`;

const FieldValue = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 700;
`;

export default Profile;
