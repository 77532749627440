import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/cities",
  headers: authHeader("hal+"),
});

class CityService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllCities() {
    let params = {
      "page": 1,
      "_limit": 1000,
      "order[name]": "asc"
    };
    return authAxios.get("", { withCredentials: true, params });
  }

  getCityById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }
}

export default new CityService();
