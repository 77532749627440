import React, { Component } from "react";
import styled from "styled-components";
import SettingsLayout from "./SettingsLayout";
import { connect } from "react-redux";
import Profile from "../components/Profile";
import Header from "../helpers/Header";

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    const state = this.props.location.state;
    this.state = {
      successMessage: "",
      errorMessage: "",
      error: false,
      success: false,
      componentState: state !== undefined ? state : undefined,
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 9 });
    document.title = "Profil - MonPharmacien";
    // const state = this.props.location.state;
    // console.log(state);
    // if (state && state !== undefined) {
    //   // this.showNotification("success", state.successMessage);
    //   this.setState({componentState: state}, () => console.log(this.state.componentState));
    // }
  }

  showNotification = (type, message) => {
    if (type === "success") {
      this.setState({ success: true, successMessage: message }, () => {
        setTimeout(
          () => this.setState({ success: false, successMessage: "" }),
          5000
        );
      });
    }
    if (type === "error") {
      this.setState({ error: true, errorMessage: message }, () => {
        setTimeout(
          () => this.setState({ error: false, errorMessage: "" }),
          5000
        );
      });
    }
  };

  goToAddOrUpdate = () => {
    this.props.history.push("/admin/settings/profile/update");
  };

  render() {
    const {
      successMessage,
      errorMessage,
      success,
      error,
      componentState,
    } = this.state;
    return (
      <>
        <Header></Header>
        <SettingsLayout
          title="Profil"
          topButtonOptions={{ btnText: "Modifier" }}
          topButtonMethod={this.goToAddOrUpdate}
          active={1}
          open={1}
          success={success}
          error={error}
          successMessage={successMessage}
          errorMessage={errorMessage}
          componentState={componentState}
        >
          <FirstBlock>
            {/* <div className="block_header">
              {success ? <FlashMessages successMessage={successMessage} /> : ""}
              {error ? <FlashMessages errorMessage={errorMessage} /> : ""}
            </div> */}
            <div className="bloc_header">
              <h3>
                Paramètres {">"} Mes paramètres {">"} Profil
              </h3>
              <PopUp></PopUp>
              <div className="bloc_header_btns"></div>
            </div>
            <BlockContent>
              <BlockContentTitle>
                cette page contient vos informations et paramètres.
              </BlockContentTitle>
            </BlockContent>
          </FirstBlock>
          <Profile {...this.props} flashNotification={this.showNotification} />
        </SettingsLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};

export default connect(mapStateToProps)(ProfileContainer);

const FirstBlock = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const BlockContentTitle = styled.p`
  padding: 10px 0;
  background: #f6f6f6;
  border: 2px solid #b2b2b2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
