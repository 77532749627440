import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/administration/patients",
});

const resetPaswwordAxios = axios.create({
  baseURL: api_url + "/app/patients/reset-password",
});

class CategoryService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllPatients( currentPage, limit, orderBy, order, firstName = "", lastName = "", email = "", createdAt = "", confirmed = "", status = "", registrationChannel = "") {
    let params = {
      "page": currentPage,
      "limit": limit,
      "order": order,
      "order_by": orderBy
    };
    let data = {};
    if (firstName.length > 0) {
      data["firstName"] = firstName;
    }
    if (lastName.length > 0) {
      data["lastName"] = lastName;
    }
    if (email.length > 0) {
      data["email"] = email;
    }
    if (createdAt.length > 0) {
      data["createdAt"] = createdAt;
    }
    if (confirmed.length > 0) {
      data["confirmed"] = confirmed;
    }
    if (status.length > 0) {
      data["status"] = status;
    }
    if (registrationChannel.length > 0) {
      data["registrationChanel"] = registrationChannel;
    }
    
    return authAxios.post("", data,{ withCredentials: true, params });
  }

  getPatientById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  updatePatient(id, data) {
    return authAxios.patch(`/${id}`, data, { withCredentials: true });
  }

  changePatientStatus(id) {
    return authAxios.patch(`/${id}/change-status`, null, { withCredentials: true });
  }

  resetPassword(email) {
    return resetPaswwordAxios.post("", {email});
  }

  // getCollaborator(id) {
  //   return authAxios.get(`/${id}`);
  // }

  // getCollaboratorNotifications(id) {
  //   return authAxios.get(`/${id}/notifications`);
  // }

  // getManagerTeamDemands(id) {
  //   return authAxios.get(`/${id}/team/demands`);
  // }

  // getCollaboraterDemands(id, page, limit) {
  //   return authAxios.get(`/${id}/demands?page=${page}&limit=${limit}`);
  // }

  // getCollaboraterContracts(id, page, limit) {
  //   return authAxios.get(`/${id}/contracts?page=${page}&limit=${limit}`);
  // }

  // markAllNotificationsAsSeen(id) {
  //   return authAxios.patch(`/${id}/notifications/mark_all_as_seen`);
  // }

  // getAllCollaborators(data, currentPage, limit) {
  //   if (data !== null && currentPage !== null && limit !== null) {
  //     return authAxios.post(`/get?page=${currentPage}&limit=${limit}`, data);
  //   } else {
  //     return authAxios.post("/get", "");
  //   }
  // }

  // persistCollaborator(collaborator) {
  //   return authAxios.post("/register", collaborator);
  // }

  // updateCollaborator(id, collaborator) {
  //   return authAxios.patch(`/${id}`, collaborator);
  // }

  // deleteCollaborator(id) {
  //   return authAxios.delete(`/${id}`);
  // }

  // reviewDemand(colId, demandId, adminStatus) {
  //   return authAxios.patch(`/${colId}/demands/${demandId}/admin_review`, {
  //     adminStatus,
  //   });
  // }

  // reviewDemandAsManager(colId, demandId, managerStatus) {
  //   return authAxios.patch(`/${colId}/demands/${demandId}/manager_review`, {
  //     managerStatus,
  //   });
  // }

  // generateCollaboraterContract(colId, model_id) {
  //   return authAxios.post(`/${colId}/generate_contract`, { model_id });
  // }

  // reactPost(colId, postId) {
  //   return authAxios.patch(`/${colId}/react-post/${postId}`, null);
  // }
}

export default new CategoryService();
