import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/categories",
  headers: authHeader("hal+"),
});

class CategoryService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllCategories( currentPage, limit, orderBy, order, name = "", showInMenu = "", orderInMenu = null) {
    let key = `order[${orderBy}]`;
    let params = {
      "page": currentPage,
      "_limit": limit,
      [key]: order
    };
    if (name.length > 0) {
      params["name"] = name;
    }
    if (showInMenu.length > 0) {
      params["showInMenu"] = showInMenu;
    }
    if (orderInMenu) {
      params["orderInMenu"] = orderInMenu;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getCategoryById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistCategory(data) {
    return authAxios.post("", data, { withCredentials: true });
  }

  updateCategory(id, data) {
    return authAxios.patch(`/${id}`, data, {
      withCredentials: true,
      headers: { "content-type": "application/merge-patch+json" },
    });
  }

  deleteCategory(id) {
    return authAxios.delete(`/${id}`, { withCredentials: true });
  }

  // getCollaborator(id) {
  //   return authAxios.get(`/${id}`);
  // }

  // getCollaboratorNotifications(id) {
  //   return authAxios.get(`/${id}/notifications`);
  // }

  // getManagerTeamDemands(id) {
  //   return authAxios.get(`/${id}/team/demands`);
  // }

  // getCollaboraterDemands(id, page, limit) {
  //   return authAxios.get(`/${id}/demands?page=${page}&limit=${limit}`);
  // }

  // getCollaboraterContracts(id, page, limit) {
  //   return authAxios.get(`/${id}/contracts?page=${page}&limit=${limit}`);
  // }

  // markAllNotificationsAsSeen(id) {
  //   return authAxios.patch(`/${id}/notifications/mark_all_as_seen`);
  // }

  // getAllCollaborators(data, currentPage, limit) {
  //   if (data !== null && currentPage !== null && limit !== null) {
  //     return authAxios.post(`/get?page=${currentPage}&limit=${limit}`, data);
  //   } else {
  //     return authAxios.post("/get", "");
  //   }
  // }

  // persistCollaborator(collaborator) {
  //   return authAxios.post("/register", collaborator);
  // }

  // updateCollaborator(id, collaborator) {
  //   return authAxios.patch(`/${id}`, collaborator);
  // }

  // deleteCollaborator(id) {
  //   return authAxios.delete(`/${id}`);
  // }

  // reviewDemand(colId, demandId, adminStatus) {
  //   return authAxios.patch(`/${colId}/demands/${demandId}/admin_review`, {
  //     adminStatus,
  //   });
  // }

  // reviewDemandAsManager(colId, demandId, managerStatus) {
  //   return authAxios.patch(`/${colId}/demands/${demandId}/manager_review`, {
  //     managerStatus,
  //   });
  // }

  // generateCollaboraterContract(colId, model_id) {
  //   return authAxios.post(`/${colId}/generate_contract`, { model_id });
  // }

  // reactPost(colId, postId) {
  //   return authAxios.patch(`/${colId}/react-post/${postId}`, null);
  // }
}

export default new CategoryService();
