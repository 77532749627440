import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import { connect } from "react-redux";
import SideBarMenu from "../helpers/SideBarMenu";
import FlashMessages from "../helpers/FlashMessages";

class SettingsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: 1,
      active: 1,
      error: false,
      success: false,
      successMessage: this.props.successMessage,
      errorMessage: this.props.errorMessage
    };
  }
  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 9 });

    document.title = "Paramètres - MonPharmacien";
    const state = this.props.componentState;
    if (state && state !== undefined) {
      this.showNotification("success", state.successMessage);
    }
  }

  showNotification = (type, message) => {
    if (type === "success") {
      this.setState({ success: true, successMessage: message }, () => {
        setTimeout(
          () => this.setState({ success: false, successMessage: "" }),
          5000
        );
      });
    }
    if (type === "error") {
      this.setState({ error: true, errorMessage: message }, () => {
        setTimeout(
          () => this.setState({ error: false, errorMessage: "" }),
          5000
        );
      });
    }
  };

  render() {
    const {
      success,
      successMessage,
    } = this.state;
    return (
      <MainContainer className="Main_container">
          {success ? <FlashMessages successMessage={successMessage} /> : ""}
        {this.props.error ? <FlashMessages errorMessage={this.props.errorMessage} /> : ""}
        <TopOptions
          title={this.props.title ? this.props.title : "Paramètres"}
          topButtonOptions={this.props.topButtonOptions}
        >
          {this.props.topButtonOptions ? (
            <button
              className="btn_bleu"
              data-shortcut_alias="create"
              onClick={() => this.props.topButtonMethod()}
            >
              {this.props.topButtonOptions.btnText}
            </button>
          ) : (
            ""
          )}
        </TopOptions>
        <Content className="Content">
          <RightBlockContainer>
            <SideBarMenu active={this.props.active} open={this.props.open} />
          </RightBlockContainer>
          <LeftBlockContainer>{this.props.children}</LeftBlockContainer>
        </Content>
      </MainContainer >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(SettingsLayout);

const MainContainer = styled.div`
  height: 100%;
`;
const LeftBlockContainer = styled.div`
  width: 74%;
  padding-bottom: 10px;
  display: inline-block;
  vertical-align: top;
`;
const Content = styled.div`
  margin-top: 10px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
`;
const RightBlockContainer = styled.div`
  width: 24%;
  display: inline-block;
  vertical-align: top;
  margin-right: 27px;
`;