import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/clients",
  headers: authHeader("hal+"),
});

class ClientService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllClients(
    currentPage,
    limit,
    orderBy,
    order,
    id = null,
    civility = "",
    firstName = "",
    lastName = "",
    email = "",
    mobileNumber = "",
  ) {
    let key = `order[${orderBy}]`;
    let params = {
      page: currentPage,
      _limit: limit,
      [key]: order,
    };
    if (id) {
      params["id"] = id;
    }
    if (civility.length > 0) {
      params["civility"] = civility;
    }
    if (firstName.length > 0) {
      params["firstName"] = firstName;
    }
    if (lastName.length > 0) {
      params["lastName"] = lastName;
    }
    if (email.length > 0) {
      params["email"] = email;
    }
    if (mobileNumber.length > 0) {
      params["mobileNumber"] = mobileNumber;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getClientById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  getClientCarts(id, currentPage, limit) {
    let params = {
      page: currentPage,
      _limit: limit
    };
    return authAxios.get(`/${id}/carts`, { withCredentials: true, params });
  }

  getClientOrders(id, currentPage, limit) {
    let params = {
      page: currentPage,
      _limit: limit
    };
    return authAxios.get(`/${id}/orders`, { withCredentials: true, params });
  }

  // persistCategory(data) {
  //   return authAxios.post("", data, { withCredentials: true });
  // }

  // updateCategory(id, data) {
  //   return authAxios.patch(`/${id}`, data, {
  //     withCredentials: true,
  //     headers: { "content-type": "application/merge-patch+json" },
  //   });
  // }

  // deleteCategory(id) {
  //   return authAxios.delete(`/${id}`, { withCredentials: true });
  // }
}

export default new ClientService();
