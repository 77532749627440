import React, { Component } from "react";
import styled from "styled-components";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import CustomDatePicker from "./CustomDatePicker";
import cartService from "../services/cart.service";
import DatePicker, { registerLocale } from "react-datepicker";
import Moment from "moment";
import patientsService from "../services/patients.service";

class PatientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      elementsPerPage: 20,
      data: [],

      totalItems: 0,
      searchFilter: "",

      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      confirmed: "",
      status: "",
      registrationChannel: "",

      order: "desc",
      orderBy: "id",

      search: false,
      advancedSearch: false,

      is_loaded: false,
    };
  }
  componentDidMount() {
    this.setState({ is_loaded: false }, () =>
      setTimeout(() => {
        this.getAllPatients();
      }, 700)
    );
  }

  getAllPatients = async () => {
    const {
      currentPage,
      elementsPerPage,
      firstName,
      lastName,
      email,
      createdAt,
      confirmed,
      status,
      registrationChannel,
      order,
      orderBy,
    } = this.state;

    try {
      let response = await patientsService.getAllPatients(
        currentPage,
        elementsPerPage,
        orderBy,
        order,
        firstName,
        lastName,
        email,
        createdAt,
        confirmed,
        status,
        registrationChannel
      );
      this.setState({
        is_loaded: true,
        data: response.data.patients,
        totalItems: response.data.total,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  sort = (orderBy) => {
    if (this.state.orderBy === orderBy) {
      if (this.state.order === "desc") {
        this.setState({ order: "asc" }, () => this.getAllPatients());
      } else {
        this.setState({ order: "desc" }, () => this.getAllPatients());
      }
    } else {
      this.setState({ orderBy: orderBy, order: "desc" }, () =>
        this.getAllPatients()
      );
    }
  };

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.setState({ currentPage: 1, is_loaded: false }, () =>
        this.getAllPatients()
      );
    }
  }

  render() {
    const {
      is_loaded,
      data,
      search,
      currentPage,
      elementsPerPage,
      totalItems,
    } = this.state;

    const CreatedAt = ({ value, onClick }) => (
      <CustomDatePicker
        error={false}
        onClick={onClick}
        defaultValue={value}
        id="createdAt"
        label={"Chercher par date d'inscription"}
      ></CustomDatePicker>
    );

    return (
      <Bloc className="Bloc">
        <div className="bloc_header">
          <h3>Liste des patients</h3>
          <div className="bloc_header_btns">
            <a
              onClick={() =>
                this.setState({
                  advancedSearch: false,
                  search: search ? false : true,
                })
              }
              className="sprite-bloc-search"
            />
            <a
              onClick={() =>
                this.setState(
                  {
                    currentPage: 1,
                    is_loaded: false,
                    search: false,
                    firstName: "",
                    lastName: "",
                    email: "",
                    createdAt: "",
                    confirmed: "",
                    status: "",
                    registrationChannel: "",
                    order: "desc",
                    orderBy: "id",
                  },
                  () => this.getAllPatients()
                )
              }
              className="sprite-bloc-reload"
            ></a>
          </div>
        </div>
        <div className="bloc_content table_wrraper">
          <table
            className="table processableTable"
            id="26555_e2d99ecca39dab4b0c717798144e1d8f"
            data-ajax_url="/invoices"
            data-object_table="invoices"
          >
            <thead>
              {search && (
                <tr className="table_th_orderable">
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.setState({
                            firstName: e.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Prénom..."
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllPatients()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({
                            email: e.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Email..."
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllPatients()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomDatePicker
                        showTimeSelect={false}
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                          this.state.createdAt &&
                          this.state.createdAt.length > 0
                            ? Moment(
                                this.state.createdAt,
                                "YYYY-MM-DD"
                              ).toDate()
                            : ""
                        }
                        onChange={(date) => {
                          date = Moment(date).format("YYYY-MM-DD");
                          this.setState(
                            {
                              createdAt: date,
                              currentPage: 1,
                              is_loaded: false,
                            },
                            () => this.getAllPatients()
                          );
                        }}
                        id="createdAt"
                        label={"Date d'inscription..."}
                      />
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomSelect
                        onKeyDown={this.handleKeyDown.bind(this)}
                        error={false}
                        errorMessage={false}
                        onChange={(e) => {
                          this.setState({
                            confirmed: e.target.value === "myAll" ? "" : e.target.value,
                            currentPage: 1,
                          }, () => this.getAllPatients());
                        }}
                        selectedValue={this.state.confirmed}
                        id="confirmed"
                        label="Confirmé ?"
                      >
                        <option value="myAll">Tous</option>
                        <option value="true">Oui</option>
                        <option value="false">Non</option>
                      </CustomSelect>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomSelect
                        onKeyDown={this.handleKeyDown.bind(this)}
                        error={false}
                        errorMessage={false}
                        onChange={(e) => {
                          this.setState({
                            status: e.target.value === "myAll" ? "" : e.target.value,
                            currentPage: 1,
                          }, () => this.getAllPatients());
                        }}
                        selectedValue={this.state.status}
                        id="status"
                        label="Statut"
                      >
                        <option value="myAll">Tous</option>
                        <option value="true">Actif</option>
                        <option value="false">Inactif</option>
                      </CustomSelect>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomSelect
                        onKeyDown={this.handleKeyDown.bind(this)}
                        error={false}
                        errorMessage={false}
                        onChange={(e) => {
                          this.setState({
                            registrationChannel: e.target.value === "myAll" ? "" : e.target.value,
                            currentPage: 1,
                          }, () => this.getAllPatients() );
                        }}
                        selectedValue={this.state.registrationChannel}
                        id="registrationChannel"
                        label="Canal d'inscription..."
                      >
                        <option value="myAll">Tous</option>
                        <option value="Email">Email</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Google">Google</option>
                      </CustomSelect>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff" style={{ float: "right" }}>
                    <a
                      onClick={() =>
                        this.setState(
                          { currentPage: 1, is_loaded: false },
                          () => this.getAllPatients()
                        )
                      }
                      class="search_bleu_btn table_header_search_button"
                      style={{ marginTop: "6px" }}
                    >
                      <i class="sprite-search-btn-white"></i>
                    </a>
                  </Th>
                </tr>
              )}
              <tr className="table_th_orderable">
                <Th
                  onClick={() => {
                    this.sort("firstName");
                  }}
                >
                  {((this.state.orderBy === "firstName" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "firstName") && <OrderUp />}
                  {((this.state.orderBy === "firstName" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "firstName") && <OrderDown />}
                  Prénom
                </Th>
                <Th
                  onClick={() => {
                    this.sort("email");
                  }}
                >
                  {((this.state.orderBy === "email" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "email") && <OrderUp />}
                  {((this.state.orderBy === "email" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "email") && <OrderDown />}
                  Email
                </Th>
                <Th
                  onClick={() => {
                    this.sort("createdAt");
                  }}
                >
                  {((this.state.orderBy === "createdAt" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "createdAt") && <OrderUp />}
                  {((this.state.orderBy === "createdAt" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "createdAt") && <OrderDown />}
                  Date d'inscription
                </Th>
                <Th
                  onClick={() => {
                    this.sort("confirmed");
                  }}
                >
                  {((this.state.orderBy === "confirmed" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "confirmed") && <OrderUp />}
                  {((this.state.orderBy === "confirmed" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "confirmed") && <OrderDown />}
                  Confirmation
                </Th>
                <Th
                  onClick={() => {
                    this.sort("status");
                  }}
                >
                  {((this.state.orderBy === "status" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "status") && <OrderUp />}
                  {((this.state.orderBy === "status" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "status") && <OrderDown />}
                  Statut
                </Th>
                <Th
                  onClick={() => {
                    this.sort("registrationChannel");
                  }}
                >
                  {((this.state.orderBy === "registrationChannel" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "registrationChannel") && (
                    <OrderUp />
                  )}
                  {((this.state.orderBy === "registrationChannel" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "registrationChannel") && (
                    <OrderDown />
                  )}
                  Canal d'inscription
                </Th>
                <Th className="table_actions" />
              </tr>
            </thead>
            {!is_loaded && (
              <tbody>
                {[1, 2, 3].map((index) => {
                  return (
                    <Tr key={"key__" + index}>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td className="table_action">
                        <a
                          className="sprite-table-edit-btn  tooltip"
                          title="Modifier"
                          data-confirm_msg
                          data-removeable_element="parents::tr"
                          target="_blank"
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            )}

            {is_loaded && data && (
              <tbody>
                {data &&
                  data.map((patient, index) => {
                    return (
                      <Tr
                        key={index}
                        onClick={() => {
                          this.props.history.push(
                            `/admin/patients/view/${patient.id}`
                          );
                        }}
                      >
                        <Td>{patient.firstName}</Td>
                        <Td>{patient.email}</Td>
                        <Td>
                          {Moment(patient.createdAt).format("YYYY-MM-DD HH:mm")}
                        </Td>
                        <Td>
                          {patient.confirmed ? (
                            <Tag_yes>Confirmé</Tag_yes>
                          ) : (
                            <Tag_no>Non confirmé</Tag_no>
                          )}
                        </Td>
                        <Td>
                          {patient.status ? (
                            <Tag_yes>Actif</Tag_yes>
                          ) : (
                            <Tag_no>Inactif</Tag_no>
                          )}
                        </Td>
                        <Td>{patient.registrationChannel}</Td>
                        <Td className="table_action">
                          {/* <a
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.history.push(
                                `/admin/brands/add_or_update/${brand.id}`
                              );
                            }}
                            className="sprite-table-edit-btn  tooltip"
                            title="Modifier"
                            data-confirm_msg
                            data-removeable_element="parents::tr"
                            target="_blank"
                            style={{ marginLeft: "6px" }}
                          />
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                window.confirm(
                                  "Voulez-vous vraiment supprimer cette marque ?"
                                )
                              )
                                this.deleteBrand(brand.id);
                            }}
                            className="sprite-table-delete-btn table_action_delete action_delete_row"
                            style={{ marginLeft: "6px" }}
                          /> */}
                        </Td>
                      </Tr>
                    );
                  })}
                {is_loaded && data.length !== 0 && (
                  <Pagination>
                    <Td colSpan={8} className="table_pagination">
                      <div style={{ display: "inline-block" }}>
                        {currentPage > 1 && (
                          <a
                            className="sprite-table-prev-btn"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: currentPage - 1,
                                  is_loaded: false,
                                },
                                () => {
                                  this.getAllPatients();
                                }
                              );
                            }}
                          />
                        )}
                        <span className="table_page">{currentPage}</span>
                        {currentPage < totalItems / elementsPerPage && (
                          <a
                            className="sprite-table-next-btn"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: currentPage + 1,
                                  is_loaded: false,
                                },
                                () => {
                                  this.getAllPatients();
                                }
                              );
                            }}
                          />
                        )}
                      </div>
                    </Td>
                  </Pagination>
                )}
              </tbody>
            )}
          </table>
        </div>
        {/* {data && data.contacts.length == 0 && (
          <NoResultCas
            btnOnClick={() => this.props.history.push("/add_contact")}
            btnText="Créer un contact"
            textInfos="Vous n’avez aucun contact pour le moment"
          ></NoResultCas>
        )} */}
      </Bloc>
    );
  }
}

const Th = styled.th`
  cursor: pointer;
  background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
  border-top: 1px solid #8d8d8d;
  wordwrap: "break-word";
  font-weight: 600;
  padding: 6px 5px;
`;
const Tr = styled.tr`
  cursor: pointer;
`;
const Pagination = styled.tr`
  &:hover {
    background-color: #f8f8f8 !important;
  }
`;
const Tag_no = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_yes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
const OrderUp = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 6px;
  height: 4px;
  ${"" /* width: 100px;
    height: 100px; */}
  background-position: -213px -98px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -4px;
`;
const OrderDown = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  height: 5px;
  background-position: -195px -98px;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: 4px;
`;
const SearchInputContainer = styled.div`
  height: 38px;
  position: relative;
`;
const SearchIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
`;
const Svg = styled.svg`
  width: 15px;
`;
const SearchTr = styled.tr`
  background: "#fff";
`;
const SearchBtn = styled.button`
  width: 28px;
  height: 27px;
  outline: none;
  border: 0;
  background: #00beb0;
  position: relative;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 3px;
`;
const SearchBtnIcon = styled.svg`
  width: 15px;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
`;
const Avatar = styled.a`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #00beb0;
  ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
  display: inline-block;
  float: left;
  ${
    "" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */
  }
  transition: all ease-in-out .2s;
  outline: 0;
  text-decoration: none;
  ${"" /* &:hover{
        background-color: #03a79b;
    } */}
  font-size: 13px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 7px;
`;
export default PatientsTable;
