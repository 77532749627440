import React, { Component } from 'react';
import styled from "styled-components";

class ErrorMessageComp extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <ErrorMessage>
                            <svg aria-hidden="true" width="26.035" height="20.86" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#fff" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                            </svg>
                            <ErrorMessageText>{this.props.errorMessage}</ErrorMessageText>
                        </ErrorMessage>
        );
    }
}

const ErrorMessage = styled.div`
    height:50px;
    width:100%;
    background:#FF4A4A;
    text-align:center;
    padding-top:2px;
    display: flex;
    justify-content: center;
    align-items center;
`;
const ErrorMessageText = styled.div`
color:#fff;
display:inline-block;
vertical-align:top;
`;
export default ErrorMessageComp;