import React, { Component } from "react";
import styled from "styled-components";
import CustomInput from "./CustomInput";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import shippingService from "../services/shipping.service";

class ShippingCostsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      elementsPerPage: 10,
      data: [],

      totalItems: 0,
      searchFilter: "",

      id:null,
      city: "",
      price: null,
      freeFrom: null,

      orderBy: "id",
      order: "desc",

      search: false,
      advancedSearch: false,

      is_loaded: false,
    };
  }
  componentDidMount() {
    this.setState({ is_loaded: false }, () =>
      setTimeout(() => {
        this.getAllShippingCosts();
      }, 700)
    );
  }

  getAllShippingCosts = async () => {
    const {
      id,
      city,
      price,
      freeFrom,
      orderBy,
      order,
      currentPage,
      elementsPerPage,
    } = this.state;

    try {
      let response = await shippingService.getAllShippingCosts(
        currentPage,
        elementsPerPage,
        orderBy,
        order,
        id,
        city,
        price,
        freeFrom
      );
      this.setState({
        is_loaded: true,
        data: response.data._embedded ? response.data._embedded.item : [],
        totalItems: response.data.totalItems,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  deleteShippingCost = async (id) => {
    try {
      await shippingService.deleteShippingCost(id);
      this.setState({ currentPage: 1, is_loaded: false }, () => {
        this.props.flashNotification(
          "success",
          "Le frais de livraison a été supprimé avec succès"
        );
        this.getAllShippingCosts();
      });
    } catch (error) {
      console.warn(error);
    }
  };

  sort = (orderBy) => {
    if (this.state.orderBy === orderBy) {
      if (this.state.order === "desc") {
        this.setState({ order: "asc" }, () => this.getAllShippingCosts());
      } else {
        this.setState({ order: "desc" }, () => this.getAllShippingCosts());
      }
    } else {
      this.setState({ orderBy: orderBy, order: "desc" }, () =>
        this.getAllShippingCosts()
      );
    }
  };

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.setState({ currentPage: 1, is_loaded: false }, () =>
        this.getAllShippingCosts()
      );
    }
  }

  render() {
    const {
      is_loaded,
      data,
      search,
      currentPage,
      elementsPerPage,
      totalItems,
    } = this.state;

    return (
      <Bloc className="Bloc">
        <div className="bloc_header">
          <h3>Liste des frais de livraison</h3>
          <div className="bloc_header_btns">
            <a
              onClick={() =>
                this.setState({
                  advancedSearch: false,
                  search: search ? false : true,
                })
              }
              className="sprite-bloc-search"
            />
            <a
              onClick={() =>
                this.setState(
                  {
                    currentPage: 1,
                    is_loaded: false,
                    search: false,
                    id: null,
                    city: "",
                    price: null,
                    freeFrom: null,
                    order: "id",
                    orderBy: "desc",
                  },
                  () => this.getAllShippingCosts()
                )
              }
              className="sprite-bloc-reload"
            ></a>
          </div>
        </div>
        <div className="bloc_content table_wrraper">
          <table
            className="table processableTable"
            id="26555_e2d99ecca39dab4b0c717798144e1d8f"
            data-ajax_url="/invoices"
            data-object_table="invoices"
          >
            <thead>
              {search && (
                <tr className="table_th_orderable">
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.id}
                        onChange={(value) => {
                          this.setState({
                            id: value.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Chercher par ID"
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllShippingCosts()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.city}
                        onChange={(value) => {
                          this.setState({
                            city: value.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Chercher par ville"
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllShippingCosts()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.price}
                        onChange={(value) => {
                          this.setState({
                            price: value.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Chercher par prix"
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllShippingCosts()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff">
                    <SearchInputContainer>
                      <CustomInput
                        onKeyDown={this.handleKeyDown.bind(this)}
                        value={this.state.freeFrom}
                        onChange={(value) => {
                          this.setState({
                            freeFrom: value.target.value,
                            currentPage: 1,
                          });
                        }}
                        label="Gratuit à partir de ?"
                      ></CustomInput>
                      <SearchIcon
                        onClick={() =>
                          this.setState(
                            { currentPage: 1, is_loaded: false },
                            () => this.getAllShippingCosts()
                          )
                        }
                      >
                        <Svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="search"
                          className="svg-inline--fa fa-search fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          ></path>
                        </Svg>
                      </SearchIcon>
                    </SearchInputContainer>
                  </Th>
                  <Th backgroundColor="#fff" style={{ float: "right" }}>
                    <a
                      onClick={() =>
                        this.setState(
                          { currentPage: 1, is_loaded: false },
                          () => this.getAllShippingCosts()
                        )
                      }
                      class="search_bleu_btn table_header_search_button"
                      style={{ marginTop: "6px" }}
                    >
                      <i class="sprite-search-btn-white"></i>
                    </a>
                  </Th>
                </tr>
              )}
              <tr className="table_th_orderable">
              <Th
                  onClick={() => {
                    this.sort("id");
                  }}
                >
                  {((this.state.orderBy === "id" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "id") && <OrderUp />}
                  {((this.state.orderBy === "id" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "id") && <OrderDown />}
                  ID
                </Th>
                <Th
                  onClick={() => {
                    this.sort("city.name");
                  }}
                >
                  {((this.state.orderBy === "city.name" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "city.name") && <OrderUp />}
                  {((this.state.orderBy === "city.name" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "city.name") && <OrderDown />}
                  Ville
                </Th>
                <Th
                  onClick={() => {
                    this.sort("price");
                  }}
                >
                  {((this.state.orderBy === "price" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "price") && <OrderUp />}
                  {((this.state.orderBy === "price" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "price") && <OrderDown />}
                  Prix
                </Th>
                <Th
                  onClick={() => {
                    this.sort("freeFrom");
                  }}
                >
                  {((this.state.orderBy === "freeFrom" &&
                    this.state.order === "asc") ||
                    this.state.orderBy !== "freeFrom") && <OrderUp />}
                  {((this.state.orderBy === "freeFrom" &&
                    this.state.order === "desc") ||
                    this.state.orderBy !== "freeFrom") && <OrderDown />}
                  Gratuit à partir de 
                </Th>
                <Th className="table_actions" />
              </tr>
            </thead>
            {!is_loaded && (
              <tbody>
                {[1, 2, 3].map((index) => {
                  return (
                    <Tr key={"key__" + index}>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td>
                        <ContentLoader width="160" height="20">
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="160"
                            height="20"
                          />
                        </ContentLoader>
                      </Td>
                      <Td className="table_action">
                        <a
                          className="sprite-table-edit-btn  tooltip"
                          title="Modifier"
                          data-confirm_msg
                          data-removeable_element="parents::tr"
                          target="_blank"
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            )}

            {is_loaded && data && (
              <tbody>
                {data &&
                  data.map((cost, index) => {
                    return (
                      <Tr
                        key={index}
                        // onClick={() => {
                        //   this.props.history.push(
                        //     `/admin/collaboraters/view/${collaborator.id}`
                        //   );
                        // }}
                      >
                        <Td>{cost.id}</Td>
                        <Td>{cost._embedded.city.name}</Td>
                        <Td>{cost.price.toFixed(2) + " Dhs"}</Td>
                        <Td>{cost.freeFrom && cost.freeFrom.toFixed(2) + " Dhs"}</Td>
                        <Td className="table_action">
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.history.push(
                                `/admin/shipping_costs/add_or_update/${cost.id}`
                              );
                            }}
                            className="sprite-table-edit-btn  tooltip"
                            title="Modifier"
                            data-confirm_msg
                            data-removeable_element="parents::tr"
                            target="_blank"
                            style={{ marginLeft: "6px" }}
                          />
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                window.confirm(
                                  "Voulez-vous vraiment supprimer ce frais de livraison ?"
                                )
                              )
                                this.deleteShippingCost(cost.id);
                            }}
                            className="sprite-table-delete-btn table_action_delete action_delete_row"
                            style={{ marginLeft: "6px" }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                {is_loaded && data.length !== 0 && (
                  <Pagination>
                    <Td colSpan={5} className="table_pagination">
                      <div style={{ display: "inline-block" }}>
                        {currentPage > 1 && (
                          <a
                            className="sprite-table-prev-btn"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: currentPage - 1,
                                  is_loaded: false,
                                },
                                () => {
                                  this.getAllShippingCosts();
                                }
                              );
                            }}
                          />
                        )}
                        <span className="table_page">{currentPage}</span>
                        {currentPage < totalItems / elementsPerPage && (
                          <a
                            className="sprite-table-next-btn"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: currentPage + 1,
                                  is_loaded: false,
                                },
                                () => {
                                  this.getAllShippingCosts();
                                }
                              );
                            }}
                          />
                        )}
                      </div>
                    </Td>
                  </Pagination>
                )}
              </tbody>
            )}
          </table>
        </div>
        {/* {data && data.contacts.length == 0 && (
          <NoResultCas
            btnOnClick={() => this.props.history.push("/add_contact")}
            btnText="Créer un contact"
            textInfos="Vous n’avez aucun contact pour le moment"
          ></NoResultCas>
        )} */}
      </Bloc>
    );
  }
}

const Th = styled.th`
  cursor: pointer;
  background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
  border-top: 1px solid #8d8d8d;
  wordwrap: "break-word";
  font-weight: 600;
  padding: 6px 5px;
`;
const Tr = styled.tr`
  cursor: pointer;
`;
const Pagination = styled.tr`
  &:hover {
    background-color: #f8f8f8 !important;
  }
`;
const Tag_no = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_yes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
const OrderUp = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 6px;
  height: 4px;
  ${"" /* width: 100px;
    height: 100px; */}
  background-position: -213px -98px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -4px;
`;
const OrderDown = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  height: 5px;
  background-position: -195px -98px;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: 4px;
`;
const SearchInputContainer = styled.div`
  height: 38px;
  position: relative;
`;
const SearchIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
`;
const Svg = styled.svg`
  width: 15px;
`;
const SearchTr = styled.tr`
  background: "#fff";
`;
const SearchBtn = styled.button`
  width: 28px;
  height: 27px;
  outline: none;
  border: 0;
  background: #00beb0;
  position: relative;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 3px;
`;
const SearchBtnIcon = styled.svg`
  width: 15px;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
`;
const Avatar = styled.a`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #00beb0;
  ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
  display: inline-block;
  float: left;
  ${"" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */}
  transition: all ease-in-out .2s;
  outline: 0;
  text-decoration: none;
  ${"" /* &:hover{
        background-color: #03a79b;
    } */}
  font-size: 13px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 7px;
`;
export default ShippingCostsTable;
