import React, { Component } from "react";

import CustomCheckBox from "../components/CustomCheckBox";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import productService from "../services/product.service";
import CategoriesAsyncSelect from "../helpers/CategoriesAsyncSelect";
import Header from "../helpers/Header";
import brandService from "../services/brand.service";
import CustomSelect from "../components/CustomSelect";
import adsService from "../services/ads.service";

registerLocale("fr", fr);

class AddAdPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_manager: false,
      account_error: false,
      first_name_error: false,
      last_name_error: false,
      email_error: "",
      category_error: false,

      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      title: "",
      link: "",
      openInNewTab: false,
      location: "",
      ranking: null,

      value: null,
      state: "",

      picture: null,

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
  }

  getAdById = async (id) => {
    try {
      let response = await adsService.getAdById(id);
      this.setState({
        title: response.data.title,
        link: response.data.link,
        openInNewTab: response.data.openInNewTab,
        location: response.data.location,
        ranking: response.data.ranking,
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleValidation = async () => {
    const { title, link, location, ranking, picture } = this.state;
    let errors = {};
    let formIsValid = true;

    if (title === "") {
      formIsValid = false;
      errors["title"] = "ce champs ne doit pas être vide";
    }

    if (link === "") {
      formIsValid = false;
      errors["link"] = "ce champs ne doit pas être vide";
    }

    if (location === "") {
      formIsValid = false;
      errors["location"] = "ce champs ne doit pas être vide";
    }

    if (!ranking) {
      formIsValid = false;
      errors["ranking"] = "ce champs ne doit pas être vide";
    }

    if (!picture) {
      formIsValid = false;
      errors["picture"] = "ce champs ne doit pas être vide";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  persistAd = async (data) => {
    try {
      let response = await adsService.persistAd(data);
      this.props.history.push(`/admin/ads`, {
        successMessage: " La publicité a été ajoutée avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  updateAd = async (data) => {
    try {
      let response = await adsService.updateAd(this.state.idToUpdate, data);
      this.props.history.push(`/admin/ads`, {
        successMessage: " La publicité a été modifiée avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  addOrPersistAd = async () => {
    this.setState({ errors: {} }, async () => {
      let valid = await this.handleValidation();
      if (valid) {
        var bodyFormData = new FormData();
        bodyFormData.append("title", this.state.title);
        bodyFormData.append("link", this.state.link);
        bodyFormData.append("ranking", this.state.ranking);
        bodyFormData.append("location", this.state.location);
        bodyFormData.append("openInNewTab", this.state.openInNewTab);
        bodyFormData.append("picture", this.state.picture);

        this.state.idToUpdate === 0
          ? this.persistAd(bodyFormData)
          : this.updateAd(bodyFormData);
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 7 });
    document.title = "Publicités - ParaSquare";
    //
    // this.setState({ ready: false }, () =>
    //   setTimeout(() => {
    //     this.getA();
    //     this.getAllDepartments();
    //     this.getAllJobs();
    //     this.setState({ ready: true });
    //   }, 700)
    // );
    //
    const id = this.props.match.params.id;
    if (id) {
      this.getAdById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 3000);
  }

  handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ picture: file });
    }
    if (status === "removed") {
      this.setState({ picture: null });
    }
  };

  render() {
    const {
      errorMessage,
      errors,
      title,
      location,
      openInNewTab,
      ranking,
      link,
      idToUpdate,
    } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          <FlashMessages successMessage={""} errorMessage={errorMessage} />
          <PopUp></PopUp>
          <TopOptions
            title={
              idToUpdate === 0
                ? "Ajouter une nouvelle publicité"
                : "Modifier une publicité"
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.addOrPersistAd()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire {idToUpdate === 0 ? "d'ajout" : "de modification"}
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Informations principales</h3>
                </div>
              </Row>
              <Row>
                <Col4Left>
                  <CustomInput
                    error={errors["title"]}
                    errorMessage={errors["title"]}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    value={title}
                    id="title"
                    label="Titre *"
                  ></CustomInput>
                </Col4Left>
                <Col4Middle>
                  <CustomInput
                    error={errors["link"]}
                    errorMessage={errors["link"]}
                    onChange={(e) => this.setState({ link: e.target.value })}
                    value={link}
                    id="link"
                    label="Lien *"
                  ></CustomInput>
                </Col4Middle>
                <Col4Right>
                  <CustomCheckBox
                    id="openInNewTab"
                    label="Ouvert dans un nouvel onglet ?"
                    onChange={() => {
                      this.setState({
                        openInNewTab: !this.state.openInNewTab,
                      });
                    }}
                    checked={openInNewTab}
                  ></CustomCheckBox>
                </Col4Right>
              </Row>
              <Row>
                <Col4Left>
                  <CustomSelect
                    error={errors["location"]}
                    errorMessage={errors["location"]}
                    onChange={(e) =>
                      this.setState({ location: e.target.value })
                    }
                    selectedValue={this.state.location}
                    id="location"
                    label="Emplacement *"
                  >
                    <option value={""} disabled>
                      --
                    </option>
                    <option value={"Slider"}>Slider</option>
                    <option value={"Accueil - A"}>Accueil - A</option>
                    <option value={"Accueil - B"}>Accueil - B</option>
                    <option value={"Accueil - B"}>
                      Produits - Barre latérale
                    </option>
                  </CustomSelect>
                </Col4Left>
                <Col4Middle>
                  <CustomInput
                    error={errors["ranking"]}
                    errorMessage={errors["ranking"]}
                    onChange={(e) =>
                      this.setState({
                        ranking:
                          parseInt(e.target.value) < 1
                            ? 1
                            : parseInt(e.target.value) > 10
                            ? 10
                            : parseInt(e.target.value),
                      })
                    }
                    value={ranking}
                    id="ranking"
                    label="Classement *"
                    type="number"
                    min={0}
                    step={1}
                    max={10}
                  ></CustomInput>
                </Col4Middle>
              </Row>
              <Row>
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    marginTop: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Image de la publicité</h3>
                </div>
              </Row>
              <Row>
                <Col12>
                  <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    maxFiles={1}
                    inputContent={(files, extra) =>
                      extra.reject
                        ? "Fichiers image uniquement"
                        : "Choisissez une image de la publicité"
                    }
                    canCancel={true}
                    accept="image/*"
                    // inputWithFilesContent={(files) => `${3 - files.length} more`}
                    submitButtonDisabled={false}
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: { color: "#00C389" },
                      dropzoneActive: { borderColor: "#00C389" },
                    }}
                  />
                </Col12>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const ErrorContainer = styled.div``;
const ErrorText = styled.div`
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;

const Col12 = styled.div`
  width: calc(96% + 51px);
  height: ${(props) => (props.height ? props.height : "auto")};
  display: inline-block;
  vertical-align: top;
`;
const Col4Left = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;
const Col4Middle = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 25.5px;
`;
const Col4Right = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 25.5px;
  text-align: center;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(AddAdPage);
