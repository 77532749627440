import React, { Component } from "react";

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import categoryService from "../services/category.service";
import Header from "../helpers/Header";

registerLocale("fr", fr);

class AddCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_manager: false,
      account_error: false,
      first_name_error: false,
      last_name_error: false,
      email_error: "",
      category_error: false,

      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      fields: {
        ...this.initialFields,
      },

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.showError = this.showError.bind(this);
  }

  initialFields = {
    name: "",
    showInMenu: "",
    orderInMenu: null,
  };

  getcategoryById = async (id) => {
    try {
      let response = await categoryService.getCategoryById(id);
      this.setState({
        fields: { ...this.state.fields, ...response.data },
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    if (input === "showInMenu") {
      fieldsCopy[input] = e.target.value === "true";
      if (e.target.value === "false") {
        fieldsCopy["orderInMenu"] = null;
      }
    }
    if (input === "orderInMenu") {
      fieldsCopy[input] =
        parseInt(e.target.value) < 1
          ? 1
          : parseInt(e.target.value);
    }
    this.setState({ fields: fieldsCopy });
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["name"] === "") {
      formIsValid = false;
      errors["name"] = "ce champs ne doit pas être vide";
    }

    if (fields["showInMenu"] === "") {
      formIsValid = false;
      errors["showInMenu"] = "veuillez choisir un choix";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  checkCategory = async () => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));

    delete fieldsCopy._links;
    delete fieldsCopy.id;
    delete fieldsCopy.status;
    delete fieldsCopy.createdAt;
    delete fieldsCopy.updatedAt;

    return fieldsCopy;
  };

  persistCategory = async () => {
    try {
      let response = await categoryService.persistCategory(this.state.fields);
      this.setState({ fields: { ...this.props.initialFields } });
      this.props.history.push(`/admin/categories`, {
        successMessage: " La catégorie a été ajoutée avec succès",
      });
    } catch (error) {
      // if (error.response) this.showError(error.response.data.message);
      // console.warn(error);
      if (error.response) {
        if (error.response.data.detail.includes("order_in_menu")) {
          this.showError(
            " L'ordre dans la menu que vous avez saisi existe déjà"
          );
        }
      }
      console.warn(error.response ? error.response.data.detail : error);
    }
  };

  updateCategory = async () => {
    try {
      let response = await categoryService.updateCategory(
        this.state.idToUpdate,
        this.state.fields
      );
      this.props.history.push(`/admin/categories`, {
        successMessage: " La catégorie a été modifiée avec succès",
      });
    } catch (error) {
      if (error.response) {
        if (error.response.data.detail.includes("order_in_menu")) {
          this.showError(
            " L'ordre dans la menu que vous avez saisi existe déjà"
          );
        }
      }
      console.warn(error.response ? error.response.data.detail : error);
    }
  };

  addOrPersistCategory = async () => {
    this.setState({ errors: {} }, async () => {
      let valid1 = await this.handleValidation();
      if (valid1) {
        let fieldsCopy = await this.checkCategory();
        this.setState({ fields: fieldsCopy }, () => {
          this.state.idToUpdate === 0
            ? this.persistCategory()
            : this.updateCategory();
        });
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Catégories - ParaSquare";
    //
    // this.setState({ ready: false }, () =>
    //   setTimeout(() => {
    //     this.getAllCollaborators();
    //     this.getAllDepartments();
    //     this.getAllJobs();
    //     this.setState({ ready: true });
    //   }, 700)
    // );
    //
    const id = this.props.match.params.id;
    if (id) {
      this.getcategoryById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    console.log(message);
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 5000);
  }

  render() {
    const { errorMessage, errors, fields, idToUpdate } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          {errorMessage && (
            <FlashMessages successMessage={""} errorMessage={errorMessage} />
          )}
          <PopUp></PopUp>
          <TopOptions
            title={
              idToUpdate === 0
                ? "Ajouter une nouvelle catégorie"
                : "Modifier une catégorie"
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.addOrPersistCategory()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire {idToUpdate === 0 ? "d'ajout" : "de modification"}
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>détails de la catégorie</h3>
                </div>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={errors["name"]}
                    errorMessage={errors["name"]}
                    onChange={this.handleChange("name")}
                    value={fields["name"]}
                    id="name"
                    label="name *"
                  ></CustomInput>
                </LeftInputContainer>
                <RightInputContainer>
                  <CustomSelect
                    error={errors["showInMenu"]}
                    errorMessage={errors["showInMenu"]}
                    onChange={this.handleChange("showInMenu")}
                    selectedValue={fields["showInMenu"]}
                    id="showInMenu"
                  >
                    <option value="" disabled>
                      Affichée dans le menu ?
                    </option>
                    <option value={"true"}>Oui</option>
                    <option value={"false"}>Non</option>
                  </CustomSelect>
                </RightInputContainer>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={false}
                    errorMessage={false}
                    onChange={this.handleChange("orderInMenu")}
                    value={fields["orderInMenu"]}
                    id="email"
                    label="Ordre dans le menu"
                    type="number"
                    min={1}
                    step={1}
                    disabled={fields["showInMenu"] !== true}
                    required={false}
                  ></CustomInput>
                </LeftInputContainer>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;
const LeftInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;

const RightInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 51px;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(AddCategoryPage);
