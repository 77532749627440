import React, { Component } from "react";
import styled from "styled-components";
import img from "../images/sprite_3.png";
import { withRouter } from "react-router-dom";

class CartArticlesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      elementsPerPage: 5,
      data: this.props.articles ? this.props.articles : [],
      totalItems: 0,
      searchFilter: "",
    };
  }
  componentDidMount() {
    // console.log(this.state.data);
    // this.setState({ is_loaded: false }, () =>
    //   this.getCollaboraterDemands(this.props.id)
    // );
  }

  render() {
    const {
      is_loaded,
      data
    } = this.state;

    return (
      <>
        {data.length > 0 && (
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h3>Listes des produits</h3>
              <div className="bloc_header_btns">
              </div>
            </div>
            <div className="bloc_content table_wrraper">
              <table
                className="table processableTable"
                id="26555_e2d99ecca39dab4b0c717798144e1d8f"
                data-ajax_url="/invoices"
                data-object_table="invoices"
              >
                <thead>
                  <tr className="table_th_orderable">
                    <Th>Nom du produit</Th>
                    <Th>Prix/unité (avec remise)</Th>
                    <Th>Quantité</Th>
                    <Th>Prix total</Th>
                  </tr>
                </thead>

                {data && (
                  <tbody>
                    {data &&
                      data.map((article, index) => {
                        return (
                          <Tr
                            key={index}
                            onClick={() => {
                              this.props.history.push(
                                `/admin/products/view/${article.product.id}`
                              );
                            }}
                          >
                            <Td>{article.product.productName}</Td>
                            <Td>
                              {article.product.discountedPrice.toFixed(2) +
                                " Dhs"}
                            </Td>
                            <Td>{article.quantity}</Td>
                            <Td>
                              {article.product.discountedPrice *
                                article.quantity +
                                " DHs"}
                            </Td>
                          </Tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
          </Bloc>
        )}
      </>
    );
  }
}

const Th = styled.th`
  cursor: pointer;
  background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
  border-top: 1px solid #8d8d8d;
  wordwrap: "break-word";
  font-weight: 600;
  padding: 6px 5px;
`;
const Tr = styled.tr`
  cursor: pointer;
`;
const Pagination = styled.tr`
  &:hover {
    background-color: #f8f8f8 !important;
  }
`;
const OrderUp = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 6px;
  height: 4px;
  ${"" /* width: 100px;
    height: 100px; */}
  background-position: -213px -98px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -4px;
`;
const OrderDown = styled.a`
  background-image: url(${img});
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  height: 5px;
  background-position: -195px -98px;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: 4px;
`;
const SearchInputContainer = styled.div`
  height: 38px;
  position: relative;
`;
const SearchIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
`;
const Svg = styled.svg`
  width: 15px;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
`;
const Avatar = styled.a`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #00beb0;
  ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
  display: inline-block;
  float: left;
  ${"" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */}
  transition: all ease-in-out .2s;
  outline: 0;
  text-decoration: none;
  ${"" /* &:hover{
        background-color: #03a79b;
    } */}
  font-size: 13px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 7px;
`;
const TagNo = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const TagYes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
const TagWaiting = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #90cec9;
  color: #fff;
  display: inline-block;
`;

const BlockContact = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #e7055a;
  color: #860b39 !important;
  height: 50px;
  padding-top: 14px;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 10px;
`;
const TitleContact = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;
const BtnContactContainer = styled.div`
  height: 25px;
  float: right;
  display: inline-block;
  vertical-align: top;
`;
export default withRouter(CartArticlesTable);
