import axios from "axios";
import api_url from "../config";

const authAxios = axios.create({
  baseURL: api_url + "/administration/pharmacies",
});

class PharmacyService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllPharmacies(
    currentPage,
    limit,
    orderBy,
    order,
    id = null,
    slug = "",
    name = "",
    country = "",
    city = "",
    pharmacist = "",
    centralStatus = "",
    appStatus = "",
  ) {
    let params = {
      page: currentPage,
      limit: limit,
      order: order,
      orderBy: orderBy,
    };
    let data = {};
    if (id) {
      data["id"] = id;
    }
    if (slug.length > 0) {
      data["slug"] = slug;
    }
    if (name.length > 0) {
      data["name"] = name ;
    }
    if (country.length > 0) {
      data["country"] = country;
    }
    if (city.length > 0) {
      data["city"] = city;
    }
    if (pharmacist.length > 0) {
      data["pharmacist"] = pharmacist;
    }
    if (centralStatus.length > 0) {
      data["centralStatus"] = centralStatus;
    }
    if (appStatus.length > 0) {
      data["appStatus"] = appStatus;
    }

    return authAxios.post("/get", data, { withCredentials: true, params });
  }

  changePharmacyStatus(slug) {
    return authAxios.patch(`/${slug}/change-status`, null, { withCredentials: true });
  }
}

export default new PharmacyService();
