import React, { Component } from "react";

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import categoryService from "../services/category.service";
import Header from "../helpers/Header";
import shippingService from "../services/shipping.service";
import cityService from "../services/city.service";

registerLocale("fr", fr);

class AddShippingCostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_manager: false,
      account_error: false,
      first_name_error: false,
      last_name_error: false,
      email_error: "",
      category_error: false,

      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      fields: {
        ...this.initialFields,
      },
      cities_loaded: false,
      allCities: [],

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.showError = this.showError.bind(this);
  }

  initialFields = {
    city: "",
    price: null,
    freeFrom: null,
  };

  getShippingCostById = async (id) => {
    try {
      let response = await shippingService.getShippingCostById(id);
      let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
      fieldsCopy["city"] = "api/cities/" + response.data._embedded.city.id;
      fieldsCopy["price"] = response.data.price;
      fieldsCopy["freeFrom"] = response.data.freeFrom
        ? response.data.freeFrom
        : null;
      this.setState({
        fields: fieldsCopy,
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (input) => (e) => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
    fieldsCopy[input] = e.target.value;
    if (input === "price") {
      fieldsCopy[input] =
        parseFloat(e.target.value) < 0 ? 0 : parseFloat(e.target.value);
    }
    if (input === "freeFrom") {
      fieldsCopy[input] =
        parseFloat(e.target.value) < 0 ? 0 : parseFloat(e.target.value);
    }
    this.setState({ fields: fieldsCopy });
  };

  getAllCities = async () => {
    try {
      let response = await cityService.getAllCities();
      this.setState({
        cities_loaded: true,
        allCities: response.data._embedded ? response.data._embedded.item : [],
      });
    } catch (error) {
      console.warn(error);
    }
  };

  handleValidation = async () => {
    const { fields } = this.state;
    let errors = {};
    let formIsValid = true;

    if (fields["city"] === "") {
      formIsValid = false;
      errors["city"] = "Veuillez choisir une ville";
    }

    if (fields["price"] === null) {
      formIsValid = false;
      errors["price"] = "Ce champs ne doit pas être vide";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  checkShippingCost = async () => {
    let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));

    delete fieldsCopy._links;
    delete fieldsCopy.id;
    delete fieldsCopy.status;
    delete fieldsCopy.createdAt;
    delete fieldsCopy.updatedAt;

    return fieldsCopy;
  };

  persistShippingCost = async () => {
    try {
      await shippingService.persistShippingCost(this.state.fields);
      this.setState({ fields: { ...this.props.initialFields } });
      this.props.history.push(`/admin/shipping_costs`, {
        successMessage: " Le frais de livraison a été ajouté avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  updateShippingCost = async () => {
    try {
      await shippingService.updateShippingCost(
        this.state.idToUpdate,
        this.state.fields
      );
      this.props.history.push(`/admin/shipping_costs`, {
        successMessage: " Le frais de livraison a été modifié avec succès",
      });
    } catch (error) {
      console.warn(error.response ? error.response.data.detail : error);
    }
  };

  addOrPersistShippingCost = async () => {
    this.setState({ errors: {} }, async () => {
      let valid1 = await this.handleValidation();
      if (valid1) {
        let fieldsCopy = await this.checkShippingCost();
        this.setState({ fields: fieldsCopy }, () => {
          this.state.idToUpdate === 0
            ? this.persistShippingCost()
            : this.updateShippingCost();
        });
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Frais de livraison - ParaSquare";
    this.getAllCities();
    //
    // this.setState({ ready: false }, () =>
    //   setTimeout(() => {
    //     this.getAllCollaborators();
    //     this.getAllDepartments();
    //     this.getAllJobs();
    //     this.setState({ ready: true });
    //   }, 700)
    // );
    //
    const id = this.props.match.params.id;
    if (id) {
      this.getShippingCostById(id);
    }
    // this.getUsers();
  }

  showError(message) {
    console.log(message);
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 3000);
  }

  render() {
    const { errorMessage, errors, fields, idToUpdate, allCities } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          {errorMessage && (
            <FlashMessages successMessage={""} errorMessage={errorMessage} />
          )}
          <PopUp></PopUp>
          <TopOptions
            title={
              idToUpdate === 0
                ? "Ajouter un frais de livraison"
                : "Modifier un frais de livraison"
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.addOrPersistShippingCost()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire {idToUpdate === 0 ? "d'ajout" : "de modification"}
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>détails du frais de livraison</h3>
                </div>
              </Row>
              <Row>
                <LeftInputContainer>
                  {this.state.cities_loaded && (
                    <CustomSelect
                      error={errors["city"]}
                      errorMessage={errors["city"]}
                      onChange={this.handleChange("city")}
                      selectedValue={fields["city"]}
                      id="city"
                    >
                      <option value="" disabled>
                        Choisissez une ville *
                      </option>
                      {allCities.map((city, key) => {
                        return (
                          <option value={"api/cities/" + city.id}>
                            {city.name}
                          </option>
                        );
                      })}
                    </CustomSelect>
                  )}
                </LeftInputContainer>
                <RightInputContainer>
                  <CustomInput
                    error={errors["price"]}
                    errorMessage={errors["price"]}
                    onChange={this.handleChange("price")}
                    value={fields["price"]}
                    id="price *"
                    label="Prix"
                    type="number"
                  ></CustomInput>
                </RightInputContainer>
              </Row>
              <Row>
                <LeftInputContainer>
                  <CustomInput
                    error={false}
                    errorMessage={false}
                    onChange={this.handleChange("freeFrom")}
                    value={fields["freeFrom"]}
                    id="freeFrom"
                    label="Gratuit à partir de "
                    type="number"
                  ></CustomInput>
                </LeftInputContainer>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;
const LeftInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;

const RightInputContainer = styled.div`
  width: 48%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 51px;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(AddShippingCostPage);
