import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/orders",
  headers: authHeader("hal+"),
});

class OrderService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllOrders(
    currentPage,
    limit,
    orderBy,
    order,
    id = null,
    clientFirstName = "",
    clientLastName = "",
    deliveryCity = "",
    ttc = null,
    willBeDelivered = null,
    status = "",
    createdAt = ""
  ) {
    let key = `order[${orderBy}]`;
    let params = {
      page: currentPage,
      _limit: limit,
      [key]: order,
    };
    if (id) {
      params["id"] = id;
    }
    if (clientFirstName.length > 0) {
      params["clientFirstName"] = clientFirstName;
    }
    if (clientLastName.length > 0) {
      params["clientLastName"] = clientLastName;
    }
    if (deliveryCity.length > 0) {
      params["deliveryCity.name"] = deliveryCity;
    }
    if (ttc) {
      params["originCart.ttc"] = ttc;
    }
    if (willBeDelivered) {
      params["willBeDelivered"] = willBeDelivered;
    }
    if (createdAt.length > 0) {
      params["orderDate[before]"] = createdAt;
    }
    if (status.length > 0) {
      params["status"] = status;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getOrderById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  updateOrderStatus(id, data) {
    return authAxios.patch(`/${id}/change-status`, data, { withCredentials: true });
  }

  // persistCategory(data) {
  //   return authAxios.post("", data, { withCredentials: true });
  // }

  // updateCategory(id, data) {
  //   return authAxios.patch(`/${id}`, data, {
  //     withCredentials: true,
  //     headers: { "content-type": "application/merge-patch+json" },
  //   });
  // }

  // deleteCategory(id) {
  //   return authAxios.delete(`/${id}`, { withCredentials: true });
  // }
}

export default new OrderService();
