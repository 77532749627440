import React, { Component } from "react";

import CustomCheckBox from "../components/CustomCheckBox";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import productService from "../services/product.service";
import CategoriesAsyncSelect from "../helpers/CategoriesAsyncSelect";
import Header from "../helpers/Header";
import brandService from "../services/brand.service";
import CustomSelect from "../components/CustomSelect";

registerLocale("fr", fr);

class AddProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_manager: false,
      account_error: false,
      first_name_error: false,
      last_name_error: false,
      email_error: "",
      category_error: false,

      ready: false,
      openPopUp: false,
      searchedText: "",
      errorMessage: "",

      idToUpdate: 0,

      productName: "",
      reference: "",
      brand: -1,
      discountedPrice: null,
      undiscountedPrice: null,
      discount: null,
      showInDiscountSection: false,
      showInNewProductsSection: false,
      availableInStock: false,
      keywords: "",
      categories: [],
      subCategories: [],
      subSubCategories: [],
      description: "",

      value: null,
      state: "",

      pictures: [],
      selectedHardwareType: null,

      allCategories: [],
      allSubCategories: [],
      allSubSubCategories: [],
      allBrands: [],

      selectedCategories: [],
      selectedSubCategories: [],
      selectedSubSubCategories: [],

      errors: {},
    };

    this.handleValidation = this.handleValidation.bind(this);
  }

  getProductById = async (id) => {
    try {
      let response = await productService.getProductById(id);
      let selectedCategories = [];
      let selectedSubCategories = [];
      let selectedSubSubCategories = [];
      let categories = [];
      let subCategories = [];
      let subSubCategories = [];
      if (response.data._embedded && response.data._embedded.categories) {
        response.data._embedded.categories.forEach((cat) => {
          let c = {
            label: cat.name,
            value: cat.id,
          };
          let categ = {
            id: cat.id,
          };
          selectedCategories.push(c);
          categories.push(categ);
        });
      }
      if (response.data._embedded && response.data._embedded.subCategories) {
        response.data._embedded.subCategories.forEach((cat) => {
          let c = {
            label: cat.name,
            value: cat.id,
          };
          let categ = {
            id: cat.id,
          };
          selectedSubCategories.push(c);
          subCategories.push(categ);
        });
      }
      if (response.data._embedded && response.data._embedded.subSubCategories) {
        response.data._embedded.subSubCategories.forEach((cat) => {
          let c = {
            label: cat.name,
            value: cat.id,
          };
          let categ = {
            id: cat.id,
          };
          selectedSubSubCategories.push(c);
          subSubCategories.push(categ);
        });
      }
      this.setState({
        productName: response.data.productName,
        reference: response.data.reference,
        brand: response.data._embedded.brand.id,
        discountedPrice: response.data.discountedPrice,
        undiscountedPrice: response.data.undiscountedPrice,
        discount: response.data.discount,
        showInDiscountSection: response.data.showInDiscountSection,
        showInNewProductsSection: response.data.showInNewProductsSection,
        availableInStock: response.data.availableInStock,
        keywords: response.data.keywords ? response.data.keywords : "",
        categories: categories,
        subCategories: subCategories,
        subSubCategories: subSubCategories,
        selectedCategories: selectedCategories,
        selectedSubCategories: selectedSubCategories,
        selectedSubSubCategories: selectedSubSubCategories,
        description: response.data.description ? response.data.description : "",
        idToUpdate: response.data.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // onDrop(picture) {
  //   console.log(picture[0][0]);
  //   this.setState({
  //     imageFiles: picture,
  //   });
  // }

  handleValidation = async () => {
    const {
      productName,
      brand,
      undiscountedPrice,
      discountedPrice,
      discount,
      showInDiscountSection,
      showInNewProductsSection,
      availableInStock,
      categories,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    if (productName === "") {
      formIsValid = false;
      errors["productName"] = "ce champs ne doit pas être vide";
    }

    if (categories.length === 0) {
      formIsValid = false;
      errors["categories"] = "veuillez choisir au moins une catégorie";
    }

    if (!undiscountedPrice) {
      formIsValid = false;
      errors["undiscountedPrice"] = "ce champs ne doit pas être vide";
    }

    if (discount && (discount < 0 || discount > 100)) {
      formIsValid = false;
      errors["discount"] =
        "la remise dois être en pourcentage (entre 0 et 100)";
    }

    if (discount === null) {
      formIsValid = false;
      errors["discount"] = "ce champs ne doit pas être vide";
    }

    if (!discountedPrice) {
      formIsValid = false;
      errors["discountedPrice"] = "ce champs ne doit pas être vide";
    }

    if (brand === -1) {
      formIsValid = false;
      errors["brand"] = "ce champs ne doit pas être vide";
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });
    return formIsValid;
  };

  persistProduct = async (data) => {
    try {
      let response = await productService.persistProduct(data);
      this.props.history.push(`/admin/products/view/${response.data.id}`, {
        successMessage: " Le produit a été ajouté avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  updateProduct = async (data) => {
    try {
      let response = await productService.updateProduct(
        this.state.idToUpdate,
        data
      );
      this.props.history.push(`/admin/products/view/${response.data.id}`, {
        successMessage: " Le produit a été modifié avec succès",
      });
    } catch (error) {
      if (error.response) this.showError(error.response.data.message);
      console.warn(error);
    }
  };

  addOrPersistProduct = async () => {
    this.setState({ errors: {} }, async () => {
      let valid = await this.handleValidation();
      if (valid) {
        var bodyFormData = new FormData();
        bodyFormData.append("productName", this.state.productName);
        bodyFormData.append("brand", JSON.stringify({ id: this.state.brand }));
        // bodyFormData.append("brand", this.state.brand);
        bodyFormData.append("undiscountedPrice", this.state.undiscountedPrice);
        bodyFormData.append("discountedPrice", this.state.discountedPrice);
        bodyFormData.append("discount", this.state.discount);
        bodyFormData.append("description", this.state.description);
        bodyFormData.append(
          "showInDiscountSection",
          this.state.showInDiscountSection
        );
        bodyFormData.append(
          "showInNewProductsSection",
          this.state.showInNewProductsSection
        );
        bodyFormData.append("availableInStock", this.state.availableInStock);
        if (this.state.reference.length > 0) {
          bodyFormData.append("reference", this.state.reference);
        }
        if (this.state.keywords.length > 0) {
          bodyFormData.append("keywords", this.state.keywords);
        }
        this.state.categories.forEach((category) => {
          bodyFormData.append("categories[]", JSON.stringify(category));
        });
        this.state.subCategories.forEach((category) => {
          bodyFormData.append("subCategories[]", JSON.stringify(category));
        });
        this.state.subSubCategories.forEach((category) => {
          bodyFormData.append("subSubCategories[]", JSON.stringify(category));
        });
        if (this.state.pictures.length > 0) {
          this.state.pictures.forEach((file) => {
            bodyFormData.append("pictures[]", file);
          });
        }

        this.state.idToUpdate === 0
          ? this.persistProduct(bodyFormData)
          : this.updateProduct(bodyFormData);
      }
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 7 });
    document.title = "Produits - ParaSquare";
    //
    // this.setState({ ready: false }, () =>
    //   setTimeout(() => {
    //     this.getA();
    //     this.getAllDepartments();
    //     this.getAllJobs();
    //     this.setState({ ready: true });
    //   }, 700)
    // );
    //
    this.getAllBrands();
    const id = this.props.match.params.id;
    if (id) {
      this.getProductById(id);
    }
    // this.getUsers();
  }

  getAllBrands = async () => {
    try {
      let response = await brandService.getAllBrands(1, 100, "name", "asc");
      this.setState({
        allBrands: response.data._embedded ? response.data._embedded.item : [],
      });
    } catch (error) {
      console.warn(error);
    }
  };

  showError(message) {
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 3000);
  }

  handleChangeStatus = ({ meta, file }, status) => {
    let pictures = this.state.pictures;
    const isFound = (element) => element.lastModified === file.lastModified;

    if (status === "done") {
      pictures.push(file);
      this.setState({ pictures: pictures });
    }
    if (status === "removed") {
      pictures.splice(this.state.pictures.findIndex(isFound), 1);
      this.setState({ pictures: pictures });
    }
  };

  verifyDiscount = () => {
    const { undiscountedPrice, discount } = this.state;
    if (undiscountedPrice && discount) {
      this.setState({
        discountedPrice: parseFloat(
          undiscountedPrice - undiscountedPrice * (discount / 100)
        ).toFixed(2),
      });
    }
  };

  getSubCategoriesUrl = () => {
    let url = "sub_categories";
    this.state.categories.forEach((cat, key) => {
      if (key === 0) {
        url += "?category.id[]=" + cat.id;
      } else {
        url += "&category.id[]=" + cat.id;
      }
    });
    return url;
  };

  getSubSubCategoriesUrl = () => {
    let url = "sub_sub_categories";
    this.state.subCategories.forEach((cat, key) => {
      if (key === 0) {
        url += "?subCategory.id[]=" + cat.id;
      } else {
        url += "&subCategory.id[]=" + cat.id;
      }
    });
    return url;
  };

  // handleSubmit = (files, allFiles) => {
  //   console.log(files.map(f => f.file))
  //   // allFiles.forEach(f => f.remove())
  // }

  render() {
    const {
      errorMessage,
      errors,
      productName,
      reference,
      brand,
      discountedPrice,
      undiscountedPrice,
      discount,
      showInDiscountSection,
      showInNewProductsSection,
      availableInStock,
      keywords,
      categories,
      subCategories,
      subSubCategories,
      description,
      idToUpdate,
      allBrands,
    } = this.state;

    return (
      <>
        <Header></Header>
        <MainContainer className="Main_container">
          <FlashMessages successMessage={""} errorMessage={errorMessage} />
          <PopUp></PopUp>
          <TopOptions
            title={
              idToUpdate === 0
                ? "Ajouter un nouveau produit"
                : "Modifier un produit"
            }
          >
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() => this.addOrPersistProduct()}
            >
              Sauvegarder
            </button>
          </TopOptions>
          <Bloc className="Bloc">
            <div className="bloc_header">
              <h2>
                Formulaire {idToUpdate === 0 ? "d'ajout" : "de modification"}
              </h2>
              <div className="bloc_header_btns"></div>
            </div>
            <FormContainer>
              <Row>
                {/* <Title>Informations personnelles</Title> */}
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Informations principales</h3>
                  
                </div>
              </Row>
              <Row>
                <Col4Left>
                  <CustomInput
                    error={errors["productName"]}
                    errorMessage={errors["productName"]}
                    onChange={(e) =>
                      this.setState({ productName: e.target.value })
                    }
                    value={productName}
                    id="productName"
                    label="Nom du produit *"
                  ></CustomInput>
                </Col4Left>
                <Col4Middle>
                  <CustomSelect
                    error={errors["brand"]}
                    errorMessage={errors["brand"]}
                    onChange={(e) =>
                      this.setState({ brand: parseInt(e.target.value) })
                    }
                    selectedValue={this.state.brand}
                    id="brand"
                    label="Marque *"
                  >
                    <option value={-1}>--</option>
                    {allBrands.map((brand, key) => {
                      return <option value={brand.id}>{brand.name}</option>;
                    })}
                  </CustomSelect>
                </Col4Middle>
                <Col4Right>
                  <CustomInput
                    error={false}
                    errorMessage={false}
                    onChange={(e) =>
                      this.setState({ reference: e.target.value })
                    }
                    value={reference}
                    id="reference"
                    label="Réference"
                  ></CustomInput>
                </Col4Right>
              </Row>
              <Row>
                <Col4Left>
                  <CustomInput
                    error={errors["undiscountedPrice"]}
                    errorMessage={errors["undiscountedPrice"]}
                    onChange={(e) =>
                      this.setState(
                        {
                          undiscountedPrice:
                            parseFloat(e.target.value) < 0
                              ? 0
                              : parseFloat(e.target.value),
                        },
                        () => {
                          this.verifyDiscount();
                        }
                      )
                    }
                    value={undiscountedPrice}
                    id="undiscountedPrice"
                    label="Prix sans remise *"
                    type="number"
                    min={0}
                  ></CustomInput>
                </Col4Left>
                <Col4Middle>
                  <CustomInput
                    error={errors["discount"]}
                    errorMessage={errors["discount"]}
                    onChange={(e) =>
                      this.setState(
                        {
                          discount:
                            parseInt(e.target.value) > 100
                              ? 100
                              : parseInt(e.target.value) < 0
                              ? 0
                              : parseInt(e.target.value),
                        },
                        () => {
                          this.verifyDiscount();
                        }
                      )
                    }
                    value={discount}
                    id="discount"
                    label="Remise *"
                    type="number"
                    min={0}
                    max={100}
                    step={1}
                  ></CustomInput>
                </Col4Middle>
                <Col4Right>
                  <CustomInput
                    error={errors["discountedPrice"]}
                    errorMessage={errors["discountedPrice"]}
                    value={discountedPrice}
                    id="discountedPrice"
                    label="Prix avec remise *"
                    type="number"
                    min={0}
                    step={0.01}
                  ></CustomInput>
                </Col4Right>
              </Row>
              <Row>
                <Col12 height="82px">
                  <CustomInput
                    error={errors["description"]}
                    errorMessage={errors["description"]}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    value={description}
                    id="description"
                    label="Description *"
                    type="textarea"
                  ></CustomInput>
                </Col12>
              </Row>
              <Row>
                <Col12 height="41px">
                  <CustomInput
                    error={false}
                    errorMessage={false}
                    onChange={(e) =>
                      this.setState({ keywords: e.target.value })
                    }
                    value={keywords}
                    id="keywords"
                    label="Keywords"
                  ></CustomInput>
                </Col12>
              </Row>
              <Row>
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    marginTop: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Catégorisation</h3>
                  
                </div>
              </Row>
              <Row>
                <Col4Left>
                  <CategoriesAsyncSelect
                    fetchedUrl="categories"
                    HTTPMethod="getcategories"
                    optionLabelProperty="name"
                    optionValueProperty="id"
                    value={this.state.selectedCategories}
                    placeholder="Catégories *"
                    onChange={(e) => {
                      console.log(e);
                      if (e) {
                        let categories = [];
                        e.forEach((category) => {
                          let c = {
                            id: parseInt(category["value"]),
                          };
                          categories.push(c);
                        });
                        this.setState(
                          {
                            selectedCategories: e,
                            categories: categories,
                            selectedSubCategories: [],
                            selectedSubSubCategories: [],
                            subCategories: [],
                            subSubCategories: [],
                          },
                          () => {
                            console.log(
                              "selectedCategories",
                              this.state.categories
                            );
                          }
                        );
                      }
                    }}
                  />
                  {errors["categories"] && (
                    <ErrorContainer>
                      <svg
                        aria-hidden="true"
                        width="16.035"
                        height="10.86"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="times-circle"
                        class="svg-inline--fa fa-times-circle fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#FF068B"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                        ></path>
                      </svg>
                      <ErrorText>
                        {errors["categories"]
                          ? errors["categories"]
                          : "Ce champs est obligatoire"}
                      </ErrorText>
                    </ErrorContainer>
                  )}
                </Col4Left>
                {categories.length > 0 && (
                  <Col4Middle>
                    <CategoriesAsyncSelect
                      fetchedUrl={this.getSubCategoriesUrl()}
                      HTTPMethod="getsubcategories"
                      optionLabelProperty="name"
                      optionValueProperty="id"
                      value={this.state.selectedSubCategories}
                      placeholder="Sous catégories"
                      onChange={(e) => {
                        console.log(e);
                        if (e) {
                          let subCategories = [];
                          e.forEach((category) => {
                            let c = {
                              id: parseInt(category["value"]),
                            };
                            subCategories.push(c);
                          });
                          this.setState({
                            selectedSubCategories: e,
                            subCategories: subCategories,
                            selectedSubSubCategories: [],
                            subSubCategories: [],
                          });
                        }
                      }}
                    />
                  </Col4Middle>
                )}
                {subCategories.length > 0 && (
                  <Col4Right>
                    <CategoriesAsyncSelect
                      fetchedUrl={this.getSubSubCategoriesUrl()}
                      HTTPMethod="getsubsubcategories"
                      optionLabelProperty="name"
                      optionValueProperty="id"
                      value={this.state.selectedSubSubCategories}
                      placeholder="Sous sous catégories"
                      onChange={(e) => {
                        console.log(e);
                        if (e) {
                          let subSubCategories = [];
                          e.forEach((category) => {
                            let c = {
                              id: parseInt(category["value"]),
                            };
                            subSubCategories.push(c);
                          });
                          this.setState({
                            selectedSubSubCategories: e,
                            subSubCategories: subSubCategories,
                          });
                        }
                      }}
                    />
                  </Col4Right>
                )}
              </Row>
              <Row>
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    marginTop: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Paramètres d'affichage</h3>
                  
                </div>
              </Row>
              <Row>
                <Col4Left>
                  <CustomCheckBox
                    id="showInDiscountSection"
                    label="Afficher dans 'En promotion' ?"
                    onChange={() => {
                      this.setState({
                        showInDiscountSection: !this.state
                          .showInDiscountSection,
                      });
                    }}
                    checked={showInDiscountSection}
                  ></CustomCheckBox>
                </Col4Left>
                <Col4Middle>
                  <CustomCheckBox
                    id="showInNewProductsSection"
                    label="Afficher dans 'Nouveaux produits' ?"
                    onChange={() => {
                      this.setState({
                        showInNewProductsSection: !this.state
                          .showInNewProductsSection,
                      });
                    }}
                    checked={showInNewProductsSection}
                  ></CustomCheckBox>
                </Col4Middle>
                <Col4Right>
                  <CustomCheckBox
                    id="availableInStock"
                    label="Disponible en stock ?"
                    onChange={() => {
                      this.setState({
                        availableInStock: !this.state.availableInStock,
                      });
                    }}
                    checked={availableInStock}
                  ></CustomCheckBox>
                </Col4Right>
              </Row>
              <Row>
                <div
                  className="bloc_h3"
                  style={{
                    width: "98.5%",
                    marginBottom: "30px",
                    marginTop: "30px",
                    textTransform: "uppercase",
                  }}
                >
                  <span></span>
                  <h3>Photos du produit</h3>
                </div>
              </Row>
              <Row>
                <Col12>
                  <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    maxFiles={5}
                    inputContent={(files, extra) =>
                      extra.reject
                        ? "Fichiers image uniquement"
                        : "Choisissez des photos du produit (5 au max)"
                    }
                    multiple={true}
                    canCancel={true}
                    accept="image/*"
                    // inputWithFilesContent={(files) => `${3 - files.length} more`}
                    submitButtonDisabled={false}
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: { color: "#00C389" },
                      dropzoneActive: { borderColor: "#00C389" },
                    }}
                  />
                </Col12>
              </Row>
            </FormContainer>
          </Bloc>
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;
const ErrorContainer = styled.div``;
const ErrorText = styled.div`
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
`;
const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Bloc = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 96.3%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  background: #fff;
  padding-bottom: 38px;
`;

const Col12 = styled.div`
  width: calc(96% + 51px);
  height: ${(props) => (props.height ? props.height : "auto")};
  display: inline-block;
  vertical-align: top;
`;
const Col4Left = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
`;
const Col4Middle = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 25.5px;
`;
const Col4Right = styled.div`
  width: 32%;
  height: 41px;
  display: inline-block;
  vertical-align: top;
  margin-left: 25.5px;
  text-align: center;
`;
const FormContainer = styled.div`
  margin-top: 38px;
`;
const Row = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(AddProductPage);
