
import React, { Component } from 'react';
import { BrowserRouter, Route, Link, Redirect, Switch } from "react-router-dom";
// import { ProtectedUserRoute } from './protected-user-route';

import "react-datepicker/dist/react-datepicker.css";
import "./css/all_38.css";
import Header from "./helpers/Header";
import styled from "styled-components";
import Loading from "./components/loading";
import { Provider } from 'react-redux';
import Store from "./store/configureStore";
import ContactBtns from "./helpers/ContactBtns";
import Login from './helpers/Login';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/login.css";
import ClientsPage from './pages/DashboardPage';
import CategoriesPage from './pages/CategoriesPage';
import SubCategoriesPage from './pages/SubCategoriesPage';
import SubSubCategoriesPage from './pages/SubSubCategoriesPage';
import AddCategoryPage from './pages/AddCategoryPage';
import AddSubCategoryPage from './pages/AddSubCategoryPage';
import AddSubSubCategoryPage from './pages/AddSubSubCategoryPage';
import ProductsPage from './pages/ProductsPage';
import AddProductPage from './pages/AddProductPage';
import DetailsProductPage from './pages/DetailsProductPage';
import CartsPage from './pages/CartsPage';
import BrandsPage from './pages/BrandsPage';
import AddBrandPage from './pages/AddBrandPage';
import DetailsCartPage from './pages/DetailsCartPage';
import DetailsClientPage from './pages/DetailsClientPage';
import ShippingCostsPage from './pages/ShippingCostsPage';
import AddShippingCostPage from './pages/AddShippingCostPage';
import OrdersPage from './pages/OrdersPage';
import DetailsOrderPage from './pages/DetailsOrderPage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import ProtectedLoginRoute from './protected-login-route';
import AdsPage from './pages/AdsPage';
import AddAdPage from './pages/AddAdPage';
import UpdateOrderStatusPage from './pages/UpdateOrderStatusPage';
import DashboardPage from './pages/DashboardPage';
import ProfileContainer from './pages/ProfileContainer';
import ChangePassword from './pages/ChangePassword';
import UpdateAdmin from './pages/UpdateAdmin';
import PatientsPage from './pages/PatientsPage';
import DetailsPatientPage from './pages/DetailsPatientPage';
import UpdatePatientPage from './pages/UpdatePatientPage';
import PrescriptionsPage from './pages/PrescriptionsPage';
import DetailsPrescriptionPage from './pages/DetailsPrescriptionPage';
import PharmaciesPage from './pages/PharmaciesPage';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: true,
    };
  }
  componentDidMount() {
    setTimeout(() => this.setState({ is_loaded: false }), 0);
  }
  render() {
    const { is_loaded } = this.state;
    return (
      <Provider store={Store}>
        <GlobalContainer>
          {
            is_loaded ? (
              <Container>
                <Loading></Loading>
              </Container>
            ) : (<BrowserRouter>
              <div style={{ flex: 1 }}>
                {/* {authService.isAuthenticated() ? <Header {...this.props} /> : ""} */}
                
                {/* <Route path="/accounts" exact component={AccountsPage} />
                <Route path="/details_account/:id" exact component={DetailsAccountPage} />
                <Route path="/add_account" exact component={AddAccountsPage} />
                <Route path="/update_account/:id" exact component={UpdateAccountsPage} /> */}

                {/* <Route path="/contacts" exact component={ContactsPage} /> */}
                {/* my links */}
                <Switch>
                  <Redirect from="/" strict exact to="/admin/dashboard" />
                  <Redirect from="/admin" strict exact to="/admin/dashboard" />
                  <Redirect from="/admin/settings" exact to={"/admin/settings/profile"} />
                  <Route path="/admin/settings/profile" exact component={ProfileContainer}/>
                  <Route path="/admin/settings/profile/change-password" exact component={ChangePassword}/>
                  <Route path="/admin/settings/profile/update" exact component={UpdateAdmin}/>
                  <Route path="/admin/dashboard" exact component={DashboardPage}/>
                  {/* <Route path="/admin/patients" exact component={PatientsPage}/>
                  <Route path="/admin/patients/view/:id" exact component={DetailsPatientPage}/>
                  <Route path="/admin/patients/update/:id" exact component={UpdatePatientPage}/> */}
                  <Route path="/admin/pharmacies" exact component={PharmaciesPage}/>
                  {/* <Route path="/admin/prescriptions" exact component={PrescriptionsPage}/>
                  <Route path="/admin/prescriptions/view/:id" exact component={DetailsPrescriptionPage}/> */}
                  {/* <Route path="/admin/carts" exact component={CartsPage}/>
                  <Route path="/admin/carts/view/:id" exact component={DetailsCartPage}/>
                  <Route path="/admin/orders" exact component={OrdersPage}/>
                  <Route path="/admin/orders/update/:id" exact component={UpdateOrderStatusPage}/>
                  <Route path="/admin/orders/view/:id" exact component={DetailsOrderPage}/>
                  <Route path="/admin/products" exact component={ProductsPage}/>
                  <Route path="/admin/products/add_or_update" exact component={AddProductPage}/>
                  <Route path="/admin/products/add_or_update/:id" exact component={AddProductPage}/>
                  <Route path="/admin/ads" exact component={AdsPage}/>
                  <Route path="/admin/ads/add_or_update" exact component={AddAdPage}/>
                  <Route path="/admin/ads/add_or_update/:id" exact component={AddAdPage}/>
                  <Route path="/admin/products/view/:id" exact component={DetailsProductPage}/>
                  <Route path="/admin/categories" exact component={CategoriesPage}/>
                  <Route path="/admin/categories/add_or_update" exact component={AddCategoryPage} />
                  <Route path="/admin/categories/add_or_update/:id" exact component={AddCategoryPage} />
                  <Route path="/admin/categories/sub-categories" exact component={SubCategoriesPage}/>
                  <Route path="/admin/categories/sub-categories/add_or_update" exact component={AddSubCategoryPage}/>
                  <Route path="/admin/categories/sub-categories/add_or_update/:id" exact component={AddSubCategoryPage}/>
                  <Route path="/admin/categories/sub-sub-categories" exact component={SubSubCategoriesPage}/>
                  <Route path="/admin/categories/sub-sub-categories/add_or_update" exact component={AddSubSubCategoryPage}/>
                  <Route path="/admin/categories/sub-sub-categories/add_or_update/:id" exact component={AddSubSubCategoryPage}/>
                  <Route path="/admin/brands" exact component={BrandsPage}/>
                  <Route path="/admin/brands/add_or_update" exact component={AddBrandPage} />
                  <Route path="/admin/brands/add_or_update/:id" exact component={AddBrandPage} />
                  <Route path="/admin/shipping_costs" exact component={ShippingCostsPage}/>
                  <Route path="/admin/shipping_costs/add_or_update" exact component={AddShippingCostPage} />
                  <Route path="/admin/shipping_costs/add_or_update/:id" exact component={AddShippingCostPage} /> */}
                  <ProtectedLoginRoute path="/admin/login" exact={true} component={Login}/>
                </Switch>
              

                {/* <ContactBtns /> */}
              </div>
              
            </BrowserRouter>)
          }
        </GlobalContainer>
      </Provider>

    );
  }
}

const Container = styled.div`
    position:relative;
    height: 100%;
    background:#fff;
`;
const GlobalContainer = styled.div`
    height: 100%;
`;

export default App;