import React, { Component } from "react";
import AuthService from "../services/auth.service";
import logo from "../images/logo-monpharmacien.png";
import userService from "../services/user.service";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      loading: false,
      message: "",
    };
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    if (localStorage.rememberMe && localStorage.username !== "") {
      this.setState({
        rememberMe: true,
        username: localStorage.username,
        password: localStorage.password,
      });
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeCheckbox = (event) => {
    this.setState({
      rememberMe: event.target.checked,
    });
  };

  handleLogin(e) {
    const { username, password, rememberMe } = this.state;
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    if (rememberMe && username !== "") {
      localStorage.username = username;
      localStorage.password = password;
      localStorage.rememberMe = rememberMe;
    } else if(!rememberMe) {
      localStorage.clear();
    }

    AuthService.login(this.state.username, this.state.password).then(
      (response) => {
        if (response.data.connected_user_role === "ROLE_ADMIN") {
          this.props.history.push("/admin/dashboard");
        } else {
          this.setState(
            { message: "L'adresse e-mail ou le mot de passe sont incorrects" },
            () => {
              userService.logout();
            }
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  }

  render() {
    return (
      <>
        <div className="login_wrapper">
          <div className="logo-wrapper">
            <a href="/" className="logo">
              <img src={logo} alt="logo Sobrus RH" />
            </a>
            <p className="logo-slogan"></p>
          </div>
          <div className="login-top-info">
            <div className="lti-tel">
              <i className="login-tel"></i> 05 30 500 500
            </div>
            <div className="lti-assistance">
              <span>
                <i className="dash-head"></i>
                {" Support "}
              </span>
            </div>
          </div>
          {/* <div className="login-bottom-info">
            <a
              href="http://sobrus.com"
              className="lbi-bloc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="world"></i> {" sobrus.com "}
            </a>
            <div className="lbi-bloc">
              <i className="small-envelope"></i> {" contact@sobrus.com "}
            </div>
          </div> */}
          <div className="login_bg">
            <div
              className="login_container"
              style={{ top: this.state.message ? "-163.5px" : "-137.5px" }}
            >
              <div className="login_ct">
                <div className="login">
                  <p className="login_head">
                    <img src={logo} alt="logo monpharmacien" />
                  </p>
                  <div className="horizontal_line"></div>
                  <form
                    action=""
                    id="connex_form"
                    className="login_form"
                    onSubmit={this.handleLogin}
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    <div className="form_row">
                      <input
                        autoFocus="true"
                        className="input"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="E-mail"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        required={true}
                      />
                    </div>
                    <div className="form_row">
                      <input
                        className="input"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        placeholder="Mot de passe"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                    </div>
                    <div class="form_row">
                      <label for="remember_me" class="checkbox">
                        <input
                          type="checkbox"
                          name="remember_me"
                          id="remember_me"
                          checked={this.state.rememberMe}
                          onChange={this.onChangeCheckbox}
                        />
                        <span class="checked"></span>
                        Se souvenir de moi
                      </label>
                    </div>
                    <div className="form_row">
                      <input
                        type="submit"
                        name="submit"
                        id="submit"
                        value="Se connecter"
                        className="submit"
                        disabled={this.state.loading}
                      />
                    </div>
                  </form>
                  {this.state.message && (
                    <p className="login_error">
                      {this.state.message === "Invalid credentials."
                        ? "L'adresse e-mail ou le mot de passe sont incorrects"
                        : this.state.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
