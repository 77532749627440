import React, { Component } from 'react';
import styled from "styled-components";

class SuccessMessageComp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (

            <ErrorMessage>
                <svg width="26.035" height="20.86" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" className="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
                <ErrorMessageText>{this.props.successMessage}</ErrorMessageText>
            </ErrorMessage>
        );
    }
}

const ErrorMessage = styled.div`
    height:50px;
    width:100%;
    background:#00C389;
    text-align:center;
    padding-top:14px;
    border-top: 1px solid #81cacb;
`;
const ErrorMessageText = styled.div`
color:#fff;
display:inline-block;
vertical-align:top;
padding-top: 5px;
`;
export default SuccessMessageComp;