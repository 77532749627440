import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import { connect } from "react-redux";
import FlashMessages from "../helpers/FlashMessages";
import Header from "../helpers/Header";
import SubCategoriesTable from "../components/SubCategoriesTable";

class SubCategoriesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      successMessage: "",
      errorMessage: "",
      error: false,
      success: false,
    };
  }
  componentDidMount() {
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 1 });
    document.title = "Sous catégories - ParaSquare";
    // const state = this.props.location.state;
    // if (state !== undefined) {
    //   this.showNotification("success", state.successMessage);
    // }
  }

  showNotification = (type, message) => {
    if (type === "success") {
      this.setState({ success: true, successMessage: message }, () => {
        setTimeout(
          () => this.setState({ success: false, successMessage: "" }),
          5000
        );
      });
    }
    if (type === "error") {
      this.setState({ error: true, errorMessage: message }, () => {
        setTimeout(
          () => this.setState({ error: false, errorMessage: "" }),
          5000
        );
      });
    }
  };

  render() {
    const { successMessage, errorMessage, success, error } = this.state;
    return (
      <>
        <Header></Header>
        <MainContainer className="MainContainer">
          {success ? <FlashMessages successMessage={successMessage} /> : ""}
          {error ? <FlashMessages errorMessage={errorMessage} /> : ""}
          <PopUp></PopUp>
          <TopOptions title="Sous catégories">
            <button
              className="btn_pink"
              data-shortcut_alias="create"
              onClick={() =>
                this.props.history.push("/admin/categories/sub-categories/add_or_update")
              }
            >
              Ajouter une sous catégorie
            </button>
          </TopOptions>
          <SubCategoriesTable
            {...this.props}
            flashNotification={this.showNotification}
          />
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;

const PopUp = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: ;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(SubCategoriesPage);
