import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import api_url from "../config";
import userService from "./user.service";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "/products",
  headers: { ...authHeader("hal+") },
});

const authFormDataAxios = axios.create({
  baseURL: api_url + "/products",
  headers: { ...authHeader(""), "Content-Type": "multipart/form-data" },
});

class ProductService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // authService.refresh();
          // window.location.reload();
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAllProducts(
    currentPage,
    limit,
    orderBy,
    order,
    productId = null,
    productName = "",
    brand = "",
    discountedPrice = null,
    undiscountedPrice = null,
    category = -1,
    subCategory = -1,
    subSubCategory = -1,
    availableInStock = null,
    status = null
  ) {
    let key = `order[${orderBy}]`;
    let params = {
      page: currentPage,
      _limit: limit,
      [key]: order,
    };
    if (productId) {
      params["id"] = productId;
    }
    if (productName.length > 0) {
      params["productName"] = productName;
    }
    if (brand.length > 0) {
      params["brand.name"] = brand;
    }
    if (discountedPrice) {
      params["discountedPrice"] = discountedPrice;
    }
    if (undiscountedPrice) {
      params["undiscountedPrice"] = undiscountedPrice;
    }
    if (category !== -1) {
      params["categories.id"] = category;
    }
    if (subCategory !== -1) {
      params["subCategories.id"] = subCategory;
    }
    if (subSubCategory !== -1) {
      params["subSubCategories.id"] = subSubCategory;
    }
    if (availableInStock !== null) {
      params["availableInStock"] = availableInStock;
    }
    if (status !== null) {
      params["status"] = status;
    }
    return authAxios.get("", { withCredentials: true, params });
  }

  getProductById(id) {
    return authAxios.get(`/${id}`, { withCredentials: true });
  }

  persistProduct(data) {
    return authFormDataAxios.post("", data, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateProduct(id, data) {
    return authFormDataAxios.post(`/${id}?_method=PATCH`, data, {
      withCredentials: true,
    });
  }

  deleteProduct(id) {
    return authAxios.delete(`/${id}`, { withCredentials: true });
  }
}

export default new ProductService();
