import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'draft-js/dist/Draft.css';
// var proxy = require('http-proxy-middleware');
// proxy('https://server3.sobrus.com/api/');

// ReactDOM.render(
//     <PDFViewer width="100%" height="100%">{PdfDocument}</PDFViewer>,
//     document.getElementById("root")
//   );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
