
 
 const initialState = {totalUnseenNotifications: 0}

 function  changeTotalUnseenNotifications(state =  initialState,action){
    let nextState;
    switch(action.type){
         case "CHANGE_TOTAL_UNSEEN_NOTIFICATIONS":
            nextState = {
                ...state,
                totalUnseenNotifications:action.value 
            }
              return nextState || state;
        default:
            return state;
    }
}
export default changeTotalUnseenNotifications;