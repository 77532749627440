import AsyncSelect from "react-select/async";
import React, { Component } from "react";
import axios from "axios";
import api_url from "../config";
import authHeader from "../services/auth-header";
import authService from "../services/auth.service";

const authAxios = axios.create({
  baseURL: api_url,
  headers: authHeader(""),
});

const convertDataForSelectBox = (data, valueProperty, labelProperty) => {
  let convertedData = [];
  // convertedData.push({ value: "", label: "_Aucun_" });

  data.map((object) => {
    convertedData.push({
      value: valueProperty ? object[valueProperty] : "",
      label: labelProperty ? object[labelProperty] : "",
    });
  });
  return convertedData;
};

class CategoriesAsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetchedUrl: this.props.fetchedUrl,
    };
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // let response = userService.logout;
          // window.location.pathname = "/admin/login";
          // return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchedUrl !== this.props.fetchedUrl) {
      this.setState({ fetchedUrl: this.props.fetchedUrl }, () =>
        this.getData()
      );
    }
  }

  getData = async (inputValue = null, callback = () => {}) => {
    try {
      const {
        HTTPMethod,
        receivedObject,
        optionLabelProperty,
        optionValueProperty,
        onInputChange,
        posologieInputValue,
      } = this.props;
      const { fetchedUrl } = this.state;
      // for URLS that use POST HTTP method

      if (HTTPMethod.toLocaleLowerCase() === "post") {
        let data = {};
        if (inputValue) {
          data[optionLabelProperty] = {
            value: inputValue,
            operator: "contains",
          };

          onInputChange && onInputChange(inputValue);
        }
        const response = await authAxios.post(`${fetchedUrl}`, data, {
          withCredentials: true,
        });
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
        // for URLS that use GET HTTP method
      } else if (HTTPMethod.toLocaleLowerCase() === "gethardwaretypes") {
        // onInputChange && onInputChange(inputValue)
        // let searchedKeyword = " ";
        // if (posologieInputValue) {
        //     searchedKeyword = posologieInputValue;
        // }
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await authAxios.get(`${fetchedUrl}?label=${content}`);
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      } else if (HTTPMethod.toLocaleLowerCase() === "getcategories") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await authAxios.get(
          `${fetchedUrl}?order[asc]=name&page=1&_limit=100&name=${content}`,
          { withCredentials: true }
        );
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      } else if (HTTPMethod.toLocaleLowerCase() === "getsubcategories") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await authAxios.get(
          `${fetchedUrl}&order[asc]=name&page=1&_limit=100&name=${content}`,
          { withCredentials: true }
        );
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      } else if (HTTPMethod.toLocaleLowerCase() === "getsubsubcategories") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await authAxios.get(
          `${fetchedUrl}&order[asc]=name&page=1&_limit=100&name=${content}`,
          { withCredentials: true }
        );
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      }
    } catch (error) {
      this.setState({ is_loaded: false });
      console.log(error);
    }
  };
  noOptionsMessage() {
    return this.props.noOptoins;
  }

  render() {
    const {
      value,
      loadOptions,
      placeholder,
      onChange,
      defaultOptions,
      optionLabelProperty,
      optionValueProperty,
    } = this.props;
    return (
      <AsyncSelect
        value={value}
        isMulti
        noOptionsMessage={this.noOptionsMessage}
        width={"100%"}
        escapeClearsValue={true}
        isClearable={true}
        loadOptions={this.getData}
        placeholder={placeholder}
        onChange={onChange}
        defaultOptions={convertDataForSelectBox(
          this.state.data,
          optionValueProperty,
          optionLabelProperty
        )}
        styles={{
          loadingIndicator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            // display: "none",
          }),
          container: (provided, state) => ({
            ...provided,
          }),
          control: (provided, state) => ({
            ...provided,
            borderColor: "#d9e3e8",
            borderWidth: "2px",
            boxShadow: "none",
            ":hover": {
              borderColor: "#00C389",
              borderWidth: "2px",
            },
            ":focus": {
              borderColor: "#00C389",
              borderWidth: "2px",
            },
            ":focus-within": {
              borderColor: "#00C389",
              borderWidth: "2px",
            },
            ":active": {
              borderColor: "#00C389",
              borderWidth: "2px",
            },
          }),
          input: (provided, state) => ({
            ...provided,
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && "#00C389",
            color: state.isSelected && "#fff",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
            ":hover": {
              color: "#fff",
              backgroundColor: "#00C389",
            },
          }),
          clearIndicator: (provided, state) => ({
            ...provided,
            paddingRight: 0,
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
          }),
        }}
      />
    );
  }
}

export default CategoriesAsyncSelect;
