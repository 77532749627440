import React, { Component } from 'react';
import styled from "styled-components";

class CustomInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false
        };
    }

    onFocus = () => {
        if(this.props.onFocus) {
            this.setState({focus: true}, () => this.props.onFocus());
        } else {
            this.setState({focus: true});
        }
    }

    onBlur = () => {
        if(this.props.onFocus) {
            this.setState({focus: false}, () => this.props.onBlur());
        } else {
            this.setState({focus: false});
        }
    }

    render() {
        const { error } = this.props;
        const { focus } = this.state;
        return (
            
                <Container >
                    <InputContainer>
                        <Input
                            onKeyDown={this.props.onKeyDown}
                            borderColor={error && !focus ? ("#FF068B") : ("#bbc8d3")}
                            backgroundColor={this.props.disabled ? "#f5f5f5" : "#fff"}
                            type={this.props.type}
                            name="input"
                            autoFocus={this.props.autoFocus}
                            className={"input-login"}
                            onChange={this.props.onChange}
                            id={this.props.id}
                            placeholder={this.props.placeholder}
                            defaultValue={this.props.defaultValue && this.props.defaultValue}
                            value={this.props.value}
                            min={this.props.min}
                            max={this.props.max}
                            onFocus={this.onFocus} onBlur={this.onBlur}
                            step={this.props.step}
                            disabled={this.props.disabled}
                            required={'required' in this.props ? this.props.required : true}
                        />
                        <Label color={error ? ("#FF068B") : ("#284358")} id="input-label" htmlFor={this.props.id} className="label-login">{this.props.label}</Label>
                    </InputContainer>
                    {
                        error && !focus && (<ErrorContainer>
                            <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#FF068B" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                            </svg>
                            <ErrorText>{this.props.errorMessage ? this.props.errorMessage : "Ce champs est obligatoire"}</ErrorText>
                        </ErrorContainer>)
                    }
                </Container>
            
        );
    }
}
const Container = styled.div`
    width: 100%;
    height: 100%;
`;
const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const Label = styled.label`
    position:absolute;
    left: 2%;
    top: 34%;
    font-family: Open Sans !important;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: ${(props) => props.color};
`;
const Input = styled.input`
    width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  background-color: ${(props) => props.backgroundColor};
  border: solid 1px ${(props) => props.borderColor};
    border-radius: 6px;
    &:hover{
        border: solid 1px #00C389;
    };
    &:focus-within , &:valid{
        border: solid 1px #00C389;
    };
    &:focus ~ ${Label} , &:valid ~ ${Label}{
    background-color: #fff;
    top: -10%;
    width: auto;
    left: 1%;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
	color: #00C389;
    transition: all 0.1s ease;
	-webkit-animation-delay: 0s;
			animation-delay: 0s;
    }
    &:not(focus) ~ ${Label} , &:not(valid) ~ ${Label}{
    transition: all 0.1s ease;
	-webkit-animation-delay: 0s;
			animation-delay: 0s;
    }
    
`;
const ErrorContainer = styled.div`
    
`;
const ErrorText = styled.div`
    display:inline-block;
    color:#FF068B;
    font-size:10px;
    vertical-align:top;
`;
export default CustomInput;