import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

import Header from "../helpers/Header";
import clientService from "../services/client.service";
import ClientCartsTable from "../components/ClientCartsTable";
import ClientOrdersTable from "../components/ClientOrdersTable";

class DetailsClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      is_loaded: false,
      demands_loaded: false,
      clientId: null,
      client: null,

    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 7 });
    document.title = "Clients - ParaSquare";
    const state = this.props.location.state;
    if (state !== undefined) {
      this.showSuccess(state.successMessage);
    }
    this.getCurrentClient(id);
  }

  getCurrentClient = async (id) => {
    try {
      let response = await clientService.getClientById(id);
      this.setState({
        client: response.data,
        is_loaded: true,
        clientId: id,
        // cartArticles: response.data.articles,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  showSuccess(message) {
    this.setState({ successMessage: message });
    setTimeout(() => {
      this.setState({ successMessage: "" });
    }, 3000);
  }

  render() {
    const { client, successMessage, is_loaded } = this.state;
    const id = this.props.match.params.id;

    return (
      <>
        <Header></Header>
        <MainContainer className="MainContainer">
          <FlashMessages successMessage={successMessage} errorMessage="" />
          <TopOptions
            title={
              client && "Client :" + client.firstName + " " + client.lastName
            }
          ></TopOptions>
          <Content className="Content">
            <LeftBlockContainer>
              <Block>
                <div className="bloc_header">
                  <h2>Détails</h2>
                  <div className="bloc_header_btns"></div>
                </div>
                <BlockContent>
                  <TitleContainer>
                    <Title>Informations générales</Title>
                    <Bar></Bar>
                  </TitleContainer>
                  <FieldsContainer>
                    {is_loaded && client.civility && (
                      <Row>
                        <ColOne>
                          <FieldLabel>Civilité</FieldLabel>
                          {is_loaded ? (
                            <FieldValue>{client.civility}</FieldValue>
                          ) : (
                            <ContentLoader width="160" height="20">
                              <rect
                                x="0"
                                y="0"
                                rx="3"
                                ry="3"
                                width="160"
                                height="20"
                              />
                            </ContentLoader>
                          )}
                          {/* <FieldValue>{contact && contact.gestionnaire}</FieldValue> */}
                        </ColOne>
                      </Row>
                    )}
                    <Row>
                      <ColOne>
                        <FieldLabel>Prénom</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>{client.firstName}</FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                        {/* <FieldValue>{contact && contact.gestionnaire}</FieldValue> */}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Nom</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>{client.lastName}</FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row>
                      <ColOne>
                        <FieldLabel>Email</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>{client.email}</FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Numéro de téléphone</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>{client.mobileNumber}</FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                  </FieldsContainer>
                  <TitleContainer>
                    <Title>Livraison</Title>
                    <Bar></Bar>
                  </TitleContainer>
                  <FieldsContainer>
                    <Row>
                      <ColOne>
                        <FieldLabel>Adresse de livraison</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.deliveryAddress
                              ? client.deliveryAddress
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Ville de livraison</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client._embedded && client._embedded.deliveryCity
                              ? client._embedded.deliveryCity.name
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row>
                      <ColOne>
                        <FieldLabel>Pays de livraison</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.deliveryCountry
                              ? client.deliveryCountry
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                      <FieldLabel>Code postal de livraison</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.deliveryZipCode
                              ? client.deliveryZipCode
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                  </FieldsContainer>
                  <TitleContainer>
                    <Title>Facturation</Title>
                    <Bar></Bar>
                  </TitleContainer>
                  <FieldsContainer>
                    <Row>
                      <ColOne>
                        <FieldLabel>Adresse de facturation</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.billingAddress
                              ? client.billingAddress
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                        <FieldLabel>Ville de facturation</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client._embedded && client._embedded.billingCity
                              ? client._embedded.billingCity.name
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                    <Row>
                      <ColOne>
                        <FieldLabel>Pays de facturation</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.billingCountry
                              ? client.billingCountry
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColOne>
                      <ColTwo>
                      <FieldLabel>Code postal de facturation</FieldLabel>
                        {is_loaded ? (
                          <FieldValue>
                            {client.billingZipCode
                              ? client.billingZipCode
                              : "--"}
                          </FieldValue>
                        ) : (
                          <ContentLoader width="160" height="20">
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="160"
                              height="20"
                            />
                          </ContentLoader>
                        )}
                      </ColTwo>
                    </Row>
                  </FieldsContainer>
                </BlockContent>
              </Block>
              {this.state.clientId && (
                <ClientCartsTable clientId={this.state.clientId} />
              )}
              {this.state.clientId && (
                <ClientOrdersTable clientId={this.state.clientId} />
              )}
            </LeftBlockContainer>
            <RightBlockContainer>
              {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
              {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
              <TraceabilityBlock>
                <TraceabilityBlockTitle>
                  Informations de traçabilité
                </TraceabilityBlockTitle>
                <ul>
                  {/* <Li>
                  <ColOneLi>Crée par</ColOneLi>
                  <ColOneLi>
                    {hardware &&
                      hardware.createdBy.firstName +
                        " " +
                        hardware.createdBy.lastName}
                  </ColOneLi>
                </Li> */}
                  <Li>
                    <ColOneLi>Créé le</ColOneLi>
                    <ColOneLi>
                      {client &&
                        moment(client.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                    </ColOneLi>
                  </Li>
                  {/* <Li>
                  <ColOneLi>Mis à jour par</ColOneLi>
                  <ColOneLi>
                    {hardware &&
                      hardware.updatedBy.firstName +
                        " " +
                        hardware.updatedBy.lastName}
                  </ColOneLi>
                </Li> */}
                  <Li>
                    <ColOneLi>Mis à jour le</ColOneLi>
                    <ColOneLi>
                      {client &&
                        moment(client.updatedAt).format("YYYY-MM-DD hh:mm:ss")}
                    </ColOneLi>
                  </Li>
                </ul>
              </TraceabilityBlock>
            </RightBlockContainer>
          </Content>
        </MainContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.selcted,
  };
};
export default connect(mapStateToProps)(DetailsClientPage);

const ErrorContainer = styled.div``;
const ErrorText = styled.div`
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
`;
const FormRow = styled.div`
  width: 94%;
  margin: auto;
  margin-top: 18px;
`;
const Col12 = styled.div`
  width: calc(96% + 51px);
  height: ${(props) => (props.height ? props.height : "41px")};
  display: inline-block;
  vertical-align: top;
`;
const MainContainer = styled.div`
  height: 100%;
`;
const LeftBlockContainer = styled.div`
  width: 74%;
  padding-bottom: 10px;
  display: inline-block;
  vertical-align: top;
`;
const Block = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 50px;
  margin-bottom: 10px;
`;

const BlockContent = styled.div`
  width: 100%;
  padding: 10px;
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  background: #fff;
  color: #494949;
  position: absolute;
  top: -10px;
  left: 0;
  padding-right: 21px;
`;
const FieldsContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 29px;
  margin-bottom: 30px;
`;
const Content = styled.div`
  margin-top: 10px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
`;
const Row = styled.div`
  display: block;
  margin-bottom: 5px;
  width: 100%;
`;
const FieldLabel = styled.p`
  display: block;
  color: grey;
  font-weight: 600;
`;
const FieldValueGreen = styled.p`
  display: block;
  color: #00beb0;
  font: 12px "Open Sans", sans-serif;
`;
const FieldValue = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 700;
`;
const ColOne = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
`;
const ColTwo = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  background: #f6f6f6;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 48%;
  margin-left: 39px;
`;
const BlockContact = styled.div`
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  background: #e7055a;
  color: #860b39 !important;
  height: 50px;
  padding-top: 14px;
  padding-left: 9px;
  padding-right: 9px;
`;
const TitleContact = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
`;

const BtnContactContainer = styled.div`
  height: 25px;
  float: right;
  display: inline-block;
  vertical-align: top;
`;

const RightBlockContainer = styled.div`
  width: 24%;
  display: inline-block;
  vertical-align: top;
  margin-left: 27px;
`;
const TraceabilityBlock = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
  -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
  box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const TraceabilityBlockTitle = styled.p`
  padding: 10px;
  background: #f0f0f0;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  width: 100%;
`;
const Li = styled.li`
  border-top: 1px solid #8d8d8d;
  padding: 6px 5px;
  cursor: pointer;
  &:hover {
    background: #00beb0;
    > p {
      color: #fff;
    }
  }
`;
const ColOneLi = styled.p`
  font-weight: 600;
  font: 12px "Open Sans", sans-serif;
  color: #494949;
  height: 100%;
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;
const TitleContainer = styled.div`
  position: relative;
`;
const Bar = styled.div`
  width: 98%;
  height: 1px;
  background: #8d8d8d;
  margin-top: 17px;
`;

const AvatarContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #00beb0;
  background-color: #fbfbfb;
  position: absolute;
  left: 20px;
  top: 10px;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #00beb0;
`;

const Tag_no = styled.strong`
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #db544c;
  color: #fff;
  display: inline-block;
`;
const Tag_yes = styled.strong`
  padding: 3px 12px 3px 12px;
  border-radius: 3px;
  background-color: #28b9ac;
  color: #fff;
  display: inline-block;
`;
